<template>
  <div class="wcg-center">
    <div style="width: 400px;">
      <div class="wcg-content wcg-full-height">
        <div class="wcg-header">
          <a href="/">
            <img src="/WG_logo_big.svg" />
            &nbsp;<span style="border-bottom: 1px solid grey; font-weight: bold"
              ><span>Warcraft</span
              ><span class="sub">Groups</span></span
            >
          </a>
        </div>
        <div>
          <div class="has-text-white" style="position: relative">
            <h1 class="title has-text-white pt-4 mb-2 has-text-centered">
              Welcome Back
            </h1>
            <div v-if="!loaded">
              <loader></loader>
            </div>
            <form class="p-3" v-show="loaded" @submit.prevent="login">
              <div class="field">
                <label class="label has-text-white">E-Mail:</label>
                <div class="control">
                  <input
                    class="input"
                    type="email"
                    id="email"
                    autocomplete="email"
                    v-model="email"
                    required
                  />
                  <p
                    class="error"
                    v-for="error in getLoginState.email"
                    v-bind:key="error"
                  >
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-white">Password:</label>
                <div class="control">
                  <input
                    class="input"
                    type="password"
                    id="password"
                    autocomplete="current-password"
                    v-model="password"
                    required
                  />

                  <p
                    class="error"
                    v-for="error in getLoginState.password"
                    v-bind:key="error"
                  >
                    {{ error }}
                  </p>
                </div>
              </div>

              <div class="field">
                <router-link to="/forgot-password">Forgot Your Password?</router-link>
              </div>
              <!-- <div class="field">
                  <label class="checkbox">
                    <input type="checkbox" />
                    Remember me
                  </label>
                </div> -->
              <div class="field">
                <button
                  class="button is-dark is-fullwidth"
                  type="submit"
                >
                  <span>Login</span
                  >
                </button>
              </div>
              <div class="field has-text-white pt-2">
                Need an Account?
                <router-link to="/register">Register</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="faded-footer">
      <div class="has-text-white" style="opacity: 0.7">
        <p>Images by Blizzard Entertainment™</p>
        <p>
          World of Warcraft, Warcraft and Blizzard Entertainment are trademarks
          or registered trademarks of Blizzard Entertainment, Inc. in the U.S.
          and/or other countries.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../store/index";
import { AUTH_REQUEST, AUTH_RESET } from "../../store/actions/auth";
import { USER_REQUEST } from "../../store/actions/user";
import { mapGetters } from "vuex";

import Loader from "../../components/Loader.vue";

export default {
  
  components: { Loader },
  data() {
    return {
      email: "",
      password: "",
      loaded: true
    };
  },
  created: function () {
    this.$store.dispatch(AUTH_RESET);
  },
  computed: {
    ...mapGetters(["getLoginState"]),
  },
  methods: {
    login: function () {
      this.loaded = false;
      this.$store
        .dispatch(AUTH_REQUEST, { email: this.email, password: this.password })
        .then(() => {
          this.$store.dispatch(USER_REQUEST).then((res) => {
            
            this.loaded = true;
            this.$router.push(`/settings`);
          }).catch(() => {
            this.loaded = true;
          });
        }).catch(() => {
          this.loaded = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: #ffb6b6;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  text-shadow: 1px 1px black;
}
</style>

