<template>
  <div class="columns">
    <div class="column is-8-desktop is-offset-2-desktop is-12-mobile is-10-tablet is-offset-1-tablet">
    <h3 class="title is-3 has-text-white">Version 1.0</h3>
        <div class="mbg content is-small">
          <h5 class="title is-5 has-text-white">Neu</h5>
          <ul>
            <li>Featured-Streamer auf Frontpage</li>
            <li>Notification
              <ul><li>Wenn Daten für öffentliche Charaktere veraltet</li>
              <li>Wenn BattleNet nicht verbunden</li></ul>
            </li>
            <li>Passwort-Vergessen-Feature ist da</li>
          </ul>
          <h5 class="title is-5 has-text-white">Bugfixxes</h5>
          <ul>
            <li>Viele Styling/Responsive-Probleme behoben</li>
            <li>Update-Bot sollte nun nicht mehr stehenbleiben</li>
            <li>Performance-Probleme gelöst / BlackScreen sollte nun nicht mehr einfach random auftreten</li>
          </ul>
        </div>
        <h3 class="title is-3 has-text-white">Version 0.9</h3>
        <div class="mbg content is-small">
          <h5 class="title is-5 has-text-white">Neu</h5>
          <ul>
            <li>
              Profilansicht
              <ul>
                <li>Anzeige der öffentlichen Charaktere</li>
                <li>Erstelle Gruppen</li>
                <li>Anmeldungen bei Gruppen</li>
              </ul>
            </li>
            <li>Update-Bot
              <ul>
                <li>Updated euer ItemLevel und Spec</li>
                <li>Items/Skillung coming soon</li>
              </ul>
            </li>
            <li>
              Anmeldungen sind nun mit Rolle möglich
            </li>
            <li>Bei der Gruppenerstellung kann man sofort seinen Charakter mit angeben</li>
          </ul>
          <h5 class="title is-5 has-text-white">Bugfixxes</h5>
          <ul>
            <li>StyleProbleme fixxed</li>
            <li>Logo polished</li>
          </ul>
        </div>
        <h3 class="title is-3 has-text-white">Version 0.8</h3>
        <div class="mbg content is-small">
          <h5 class="title is-5 has-text-white">Neu</h5>
          <ul>
            <li>Suche nach Listen</li>
            <li>Privacy Policy</li>
            <li>Keystone_Level muss angegeben werden</li>
            <li>Voice_Programm kann angegeben werden</li>
            <li>
              Liste kann geschlossen werden und mit gut / schlecht bewertet
              werden
            </li>
            <li>Liste kann gelöscht werden</li>
            <li>
              Neu Importierte Charaktere müssen nun 60 sein (Wird noch
              verändert, damit sich der AutoUpdate an die anderen Charaktere
              erinnert)
            </li>
          </ul>
          <h5 class="title is-5 has-text-white">Bugfixxes</h5>
          <ul>
            <li>Date + Time für firefox verfügbar</li>
            <li>Keinen Zeitpunkt in der Vergangenheit auswählen</li>
            <li>Frontpage zeigt nur noch nicht vergangene Listen</li>
            <li>Pflichtfelder markiert und validiert</li>
            <li>Minor Changes / Performance</li>
          </ul>
        </div>
        </div>
  </div>
</template>



<script>
export default {
  name: "Changelog",
  data() {
    return {
      changeLog: [],
      
    }
  }
};
</script>