<template>
  <div v-if="!loaded">
    <loader></loader>
  </div>
  <div v-else>
    <div class="notfound-page" v-if="!userData">
      <meta-patcher :title="userData.name" />
      <h3 class="title is-3 has-text-white">
        Die Seite die du gesucht hast, wurde nicht gefunden.
      </h3>
      <div class="img-container">
        <img src="/img/404.png" alt="" />
      </div>
    </div>
    <div v-else>
      <div class="columns" id="userNamePanel">
        <div
          class="column is-6-desktop is-12-mobile is-10-tablet is-offset-3-desktop is-offset-1-tablet"
        >
          <div class="">
            <h1 class="title is-1 has-text-white">
              {{ userData.name }}
              <small style="font-size: 50%; color: #333"
                ><i>Last seen: {{ formatDate(userData.updated_at) }}</i></small
              >
            </h1>
          </div>
          <h3 class="headerBar has-text-white">Sichtbare Chraktere</h3>
          <character-table
            :characters="userData.wowcharacters"
            :isFrontend="true"
          ></character-table>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="row mbg">
            <list-table
              :titleSpan="'Erstellte Gruppen'"
              :listentrys="userGruppen"
              :lastPage="userGruppenLastPage"
              :curPage="userGruppenCurPage"
              v-on:parent-list-finder="findGruppen"
            >
            </list-table>
          </div>
        </div>
        <div class="column">
          <div class="row mbg">
            <list-table
              :titleSpan="'Anmeldungen'"
              :listentrys="userSignups"
              :lastPage="userSignupsLastPage"
              :curPage="userSignupsCurPage"
              v-on:parent-list-finder="findSignups"
            >
            </list-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { USER, USER_LISTS, USER_SIGNUPS } from "../../utility/routes";
import { mapGetters } from "vuex";
import { lData } from "../../utility/db";
import { METHOD_DELETE, METHOD_POST } from "../../utility/globals";
import CharacterTable from "../../components/CharacterTable.vue";
import ListTable from "../../components/ListTable.vue";
import Loader from '../../components/Loader.vue';
import MetaPatcher from '../../components/MetaPatcher.vue';

export default {
  components: { CharacterTable, ListTable, Loader, MetaPatcher },
  name: "User",
  data() {
    return {
      loaded: false,
      userData: null,
      userGruppen: null,
      userSignups: null,
      userGruppenLastPage: null,
      userSignupsLastPage: null,
      userGruppenCurPage: null,
      userSignupsCurPage: null,
    };
  },
  mounted: function () {
    this.retrieveUserData().then(() => {
      this.loaded = true;
      this.findGruppen();
      this.findSignups();
    });
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUserData", "getLoginId"]),
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    retrieveUserData: function () {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, USER, {
          user_id: this.$route.params.id,
        })
          .then((res) => {
            if (res.data.status == "success") {
              this.userData = res.data.response;
              resolve();
            }
          })
          .catch((err) => {
            this.userData = null;
          });
      });
    },
    findGruppen: function (page_num) {
      lData(METHOD_POST, USER_LISTS, {
        user_id: this.$route.params.id,
        page: typeof page_num !== "undefined" ? page_num : 1,
      }).then((res) => {
        if (res.data.status == "success") {
          if (res.data.response.total > 0) {
            this.userGruppen = res.data.response.data;
            if (this.userGruppen.length > 0) {
              this.userGruppenCurPage = res.data.response.current_page;
              this.userGruppenLastPage = res.data.response.last_page;
            }
          }
        }
      });
    },
    findSignups: function (page_num) {
      lData(METHOD_POST, USER_SIGNUPS, {
        user_id: this.$route.params.id,
        page: typeof page_num !== "undefined" ? page_num : 1,
      }).then((res) => {
        if (res.data.status == "success") {
          if (res.data.response.total > 0) {
            this.userSignups = res.data.response.data;
            if (this.userSignups.length > 0) {
              this.userSignupsCurPage = res.data.response.current_page;
              this.userSignupsLastPage = res.data.response.last_page;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" >
#userNamePanel {
  position: relative;
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
  // &::before {
  //   content: " ";
  //   // background-image: url(/img/aszhara.jpg);
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   height: 100%;
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  //   background: #ffffff6e !important;
  //   opacity: 0.2;
  //   filter: blur(1px);
  //   z-index: 1;
  // }
  > div {
    z-index: 5;
  }
}
</style>