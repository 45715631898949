<template>
  <p v-if="Object.keys(characters).length == 0">Keine Charaktere auf maximalem Level gefunden.</p>
  <table
    class="table is-charactertable is-bordered is-narrow is-hoverable is-fullwidth"
    v-else
  >
    <thead>
      <tr>
        <td class="has-text-white">Charakter</td>
        <td class="has-text-white">Realm</td>
        <td class="has-text-white">Level</td>
        <td class="has-text-white">Link</td>
        <td class="has-text-white" v-if="!isFrontend">Anzeigen</td>
        <td class="has-text-white" v-if="!isFrontend">Main-Char</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="character in characters" v-bind:key="character">
        <td>
          <router-link
                  :to="{
                    path: '/character/' + character.realm + '/' + character.character,
                  }" class="character-name">
            <img
              class="round-character-thumbnail"
              v-bind:src="
                '/img/class/' + getFormedSpan(character) + '.png'
              "
              onerror="this.onerror=null;this.src='/favicon.png';"
              height="20"
              width="20"
            />
            <span
              v-bind:class="
                character.myclass
                  ? 'class-' + getFormedSpan(character)
                  : ''
              "
              >{{ character.character }} <i class="fas fa-chess-queen" v-if="isFrontend && character.isMain"></i></span
            >
          </router-link>
        </td>
        <td>
          {{ character.realm }}
        </td>
        <td>
          {{ character.level }}
        </td>
        <td>
          <a v-bind:href="armoryLink(character)" target="_blank"><img src="/img/armory.png" height="20" width="20" v-bind:alt="character.character"></a>
        </td>
        <td v-if="!isFrontend">
          <div class="field">
            <input
              v-bind:id="'switch' + character.id"
              type="checkbox"
              v-bind:name="'switch' + character.id"
              class="switch is-outlined"
              v-bind:checked="character.show"
              @change="changeCharVisibility(character)"
            />
            <label v-bind:for="'switch' + character.id"></label>
          </div>
        </td>
        <td v-if="!isFrontend">
          <div class="field">
            <input
              type="radio"
              v-model="mainchar"
              v-bind:checked="character.isMain"
              v-bind:value="character.id"
              v-bind:name="'radiomain'"
              class="switch is-small"
              @change="changeMainChar"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: "CharacterTable",
  data() {
    return {};
  },
  props: ["characters", "isFrontend"],
  computed: {
    ...mapGetters(["isAuthenticated", "getUserData"])
  },
  methods: {
    armoryLink(character){
      return "https://worldofwarcraft.com/de-de/character/eu/"+character.realm+"/"+character.character
    },
    getFormedSpan(obj, spec) {
      if (obj != null) {
        if (typeof spec !== 'undefined') {
          return obj.myspecification.name.toLowerCase().replace(" ", "-") + "_" + obj.myclass.name.toLowerCase().replace(" ", "-");  
        }else{
          return obj.myclass.name.toLowerCase().replace(" ", "-");
        }
        
      }
    },
  },
};
</script>