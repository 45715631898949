<template>
  <div>
    <div class="columns">
      <div
        class="column is-8-desktop is-12-mobile is-10-tablet is-offset-2-desktop is-offset-1-tablet"
      >
        <div class="row">
          <div class="columns">
            <div class="column">
              <div class="container mbg mbg-small selectedFilter">
                <i
                  class="fas fa-times close"
                  v-if="selected.expansion"
                  @click="removeExpansionSelection"
                ></i>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label
                      class="label has-text-white is-bold has-text-highlight"
                      >Expansion:</label
                    >
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <div
                          class="select is-small is-fullwidth"
                          v-if="expansions && !selected.expansion"
                        >
                          <select
                            v-model="selected.expansion"
                            @change="getInstances"
                          >
                            <option
                              class="dropdown-item"
                              v-for="expansion in expansions"
                              v-bind:key="expansion"
                              v-bind:value="expansion"
                            >
                              {{ expansion.title }}
                            </option>
                          </select>
                        </div>
                        <div v-else-if="selected.expansion">
                          <input
                            class="input is-small is-static has-text-white"
                            type="text"
                            readonly
                            v-model="selected.expansion.title"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="container mbg mbg-small selectedFilter">
                <i
                  class="fas fa-times close"
                  v-if="selected.instance"
                  @click="removeInstanceSelection"
                ></i>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label
                      class="label has-text-white is-bold has-text-highlight"
                      >Instance:</label
                    >
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <div
                          class="select is-small is-fullwidth"
                          v-if="instances && !selected.instance"
                        >
                          <select
                            v-model="selected.instance"
                            @change="showForm"
                          >
                            <optgroup
                              v-for="(instanceObj, name) in instances"
                              v-bind:label="name"
                              v-bind:key="(instanceObj, name)"
                            >
                              <option
                                class="dropdown-item"
                                v-for="instance in instanceObj"
                                v-bind:key="instance"
                                v-bind:value="instance"
                              >
                                {{ instance.title }}
                              </option>
                            </optgroup>
                          </select>
                        </div>
                        <div v-else-if="selected.instance">
                          <input
                            class="input is-small is-static has-text-white"
                            type="text"
                            readonly
                            v-model="selected.instance.title"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns" v-if="editForm">
            <div class="column">
              <!-- v-bind:style="{ backgroundImage: 'url(' + selected.instance.picture+ ')' }" -->
              <div class="mbg">
                <div class="columns">
                  <div class="column">
                    <div class="columns">
                      <div class="column">
                        <div class="field">
                          <label class="label is-required">Mode:</label>
                          <div class="control">
                            <div class="select is-small is-fullwidth">
                              <select v-model="meta.mode">
                                <option
                                  v-for="mode in modeList"
                                  v-bind:key="mode"
                                >
                                  {{ mode }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column" v-if="isKeystoneSelected">
                        <div class="field">
                          <label class="label is-required">Keystone:</label>
                          <div class="control">
                            <div class="is-small is-fullwidth">
                              <input
                                v-model="meta.keystone_level"
                                type="number"
                                class="input is-small"
                                name=""
                                min="1"
                                max="31"
                                id=""
                                @input="updateKeyStoneLevel"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <label class="label is-required">Datum:</label>
                        <div class="field">
                          <p class="control is-expanded">
                            <input
                              type="date"
                              class="input is-small"
                              v-model="meta.running_date"
                              onkeydown="return false"
                              v-bind:min="dateMinimum"
                              v-bind:max="dateMaximum"
                            />
                          </p>
                        </div>
                      </div>
                      <div class="column">
                        <label class="label is-required">Uhrzeit:</label>
                        <div class="field">
                          <p class="control is-expanded">
                            <input
                              type="time"
                              class="input is-small"
                              v-model="meta.running_time"
                              v-bind:min="minimumTime"
                            />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <div class="field">
                          <label class="label is-required">Fraktion:</label>
                          <div class="control">
                            <div class="select is-small is-fullwidth">
                              <select v-model="meta.faction">
                                <option value="NEUTRAL">Neutral</option>
                                <option value="HORDE">Horde</option>
                                <option value="ALLIANCE">Allianz</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="field">
                          <label class="label"
                            >Voice Programm:&nbsp;<small class="has-text-muted"
                              >(Direktlink in die Beschreibung)</small
                            ></label
                          >
                          <div class="control">
                            <div class="select is-small is-fullwidth">
                              <select v-model="meta.voice_program">
                                <option value=""></option>
                                <option value="Discord">Discord</option>
                                <option value="Teamspeak">Teamspeak</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns" v-if="meta.faction">
                      <div class="column">
                        <div class="field">
                          <label class="label">Eigenen Char hinzufügen:</label>
                          <div class="control">
                            <div class="select is-small is-fullwidth">
                              <select v-model="meta.teilnehmer.character">
                                <option value=""></option>
                                <option
                                  v-bind:value="mChar.id"
                                  v-for="mChar in conditionalCharRender"
                                  v-bind:key="mChar"
                                >
                                  {{ mChar.character }}-{{ mChar.realm }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="field">
                          <label class="label">Teilnehmen als Rolle:</label>
                          <input
                            id="checkTank"
                            type="checkbox"
                            class="hidden highlight-button"
                            name="checkTank"
                            v-bind:checked="meta.teilnehmer.role.tank"
                            v-model="meta.teilnehmer.role.tank"
                          />
                          <label
                            class="button is-small is-light is-outlined"
                            for="checkTank"
                          >
                            <div class="entry-label">
                              <img
                                src="/img/Encounter-journal-tank.png"
                                alt=""
                              /><span>Tank</span>
                            </div></label
                          >&nbsp;
                          <input
                            id="checkHeal"
                            type="checkbox"
                            class="hidden highlight-button"
                            name="checkHeal"
                            v-bind:checked="meta.teilnehmer.role.heal"
                            v-model="meta.teilnehmer.role.heal"
                          />
                          <label class="button is-small is-light is-outlined" for="checkHeal"
                            ><div class="circle"></div>
                            <div class="entry-label">
                              <img
                                src="/img/Encounter-journal-healer.png"
                                alt=""
                              /><span>Heal</span>
                            </div></label
                          >&nbsp;
                          <input
                            id="checkDamage"
                            type="checkbox"
                            class="hidden highlight-button"
                            name="checkDamage"
                            v-bind:checked="meta.teilnehmer.role.damage"
                            v-model="meta.teilnehmer.role.damage"
                          />
                          <label
                            class="button is-small is-light is-outlined"
                            for="checkDamage"
                            ><div class="circle"></div>
                            <div class="entry-label">
                              <img
                                src="/img/Encounter-journal-dps.png"
                                alt=""
                              /><span>Damage</span>
                            </div></label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="control">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            checked="checked"
                            v-model="meta.unique_signups"
                          />
                          Eine Anmeldung pro Nutzer
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <!-- <div class="field">
                      <label class="label">Name:</label>
                      <div class="control">
                        <input
                          class="input"
                          type="text"
                          placeholder="Text input"
                        />
                      </div>
                    </div> -->
                    <div class="field">
                      <label class="label">Beschreibung:</label>
                      <div class="control">
                        <textarea
                          class="textarea"
                          v-model="meta.description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-grouped is-grouped-right">
                  <div class="control">
                    <span
                      ><label class="is-required"></label> markierte Felder sind
                      Pflichtfelder.</span
                    >&nbsp;&nbsp;
                    <button
                      type="button"
                      class="button is-dark"
                      @click="createSignUpList"
                    >
                      Anlegen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import store from "../../store/index";
import {
  STATIC_EXPANSIONS,
  STATIC_INSTANCES,
  LIST_NEW,
} from "../../utility/routes";
// import { mapGetters } from "vuex";
import { lData } from "../../utility/db";
import { METHOD_POST } from "../../utility/globals";
import { mapGetters } from "vuex";

export default {
  name: "New",
  data() {
    return {
      dateMinimum: moment(new Date()).format("YYYY-MM-DD"),
      dateMaximum: moment(new Date()).add(6,'M').format("YYYY-MM-DD"),
      expansions: null,
      instances: null,
      editForm: false,
      selected: {
        expansion: null,
        instance: null,
      },
      meta: {
        mode: null,
        minimum_itemlevel: null,
        faction: null,
        running_date: null,
        running_time: null,
        unique_signups: true,
        description: null,
        voice_program: null,
        keystone_level: null,
        teilnehmer: {
          character: null,
          role: {
            damage: null,
            heal: null,
            tank: null,
          },
        },
      },
      meta_fallback: {
        mode: null,
        minimum_itemlevel: null,
        faction: null,
        running_date: null,
        running_time: null,
        unique_signups: true,
        description: null,
        voice_program: null,
        keystone_level: null,
        teilnehmer: {
          character: null,
          role: {
            damage: null,
            heal: null,
            tank: null,
          },
        },
      },
    };
  },
  created: function () {
    this.getExpansions();
  },
  mounted: function () {},
  computed: {
    conditionalCharRender: function () {
      return this.getCharArray.filter(
        (i) => i.faction == this.meta.faction || this.meta.faction == "NEUTRAL"
      );
    },
    modeList: function () {
      return this.selected.instance.modes.split("|");
    },
    ...mapGetters(["getCharArray"]),
    isKeystoneSelected: function () {
      return this.meta.mode == "Mythic Keystone";
    },
    minimumTime: function () {
      let retVal = null;
      if (this.meta.running_date != null) {
        if (
          moment(new Date()).diff(moment(this.meta.running_date), "days") == 0
        ) {
          retVal = moment(new Date()).format("HH:mm");
        }
      }
      return retVal;
    },
  },
  methods: {
    updateKeyStoneLevel(event){
      const value = event.target.value;
      if (value > 31) {
        this.meta.keystone_level = 31
      }else if(value < 1){
        this.meta.keystone_level = 1
      }
      this.$forceUpdate()
    },
    getExpansions() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, STATIC_EXPANSIONS).then((res) => {
          if (res.data.status == "success") {
            this.expansions = res.data.response;
          }
        });
      });
    },
    getInstances() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, STATIC_INSTANCES, {
          expansion_id: this.selected.expansion.id,
        }).then((res) => {
          if (res.data.status == "success") {
            this.instances = res.data.response;
          }
        });
      });
    },
    showForm() {
      this.editForm = true;
    },
    removeExpansionSelection() {
      this.selected.expansion = null;
      this.selected.instance = null;
      this.instances = null;
      this.editForm = false;
    },
    removeInstanceSelection() {
      this.selected.instance = null;
      this.editForm = false;
    },
    createSignUpList() {
      return new Promise((resolve, reject) => {
        if (
          !this.meta.mode ||
          (this.meta.mode == "Mythic Keystone" && !this.meta.keystone_level) ||
          !this.meta.running_date ||
          !this.meta.running_time ||
          !this.meta.faction
        ) {
          this.$swal({
            title: `Alle Pflichtfelder müssen gefüllt weren.`,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          return;
        }

        if (this.meta.running_time) {
          this.meta.parsed_running_time = moment(
            `${this.meta.running_date} ${this.meta.running_time}`
          ).format();
        }

        if (
          moment(new Date()).diff(this.meta.parsed_running_time, "minutes") > 0
        ) {
          this.$swal({
            title: `Zeitpunkt muss in der Zukunft stattfinden.`,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });

          return;
        }

        lData(METHOD_POST, LIST_NEW, {
          meta: this.meta,
          instance: this.selected.instance.id,
        }).then((res) => {
          if (res.data.status == "success") {
            this.$router.push(`/list/${res.data.response}`);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
select optgroup {
  background: #131313;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  padding: 10px;
}

option {
  color: #ffffe8 !important;
  background-color: #333 !important;
}
</style>