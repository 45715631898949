<template>
  <div class="wcg-center">
    <div style="width: 400px">
      <div class="wcg-content wcg-full-height">
        <div class="wcg-header">
          <a href="/">
            <img src="/WG_logo_big.svg" />
            &nbsp;<span
              ><span>Warcraft</span><span class="sub">Groups</span></span
            >
          </a>
        </div>
        <div>
          <div class="has-text-white" style="position: relative">
            <h1 class="title has-text-white pt-4 mb-2 has-text-centered">
              Join us now
            </h1>
            <div v-if="!loaded">
              <loader></loader>
            </div>
            <form class="p-3" v-show="loaded" @submit.prevent="register">
              <div class="field">
                <label class="label has-text-white">Username:</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    id="username"
                    v-model="username"
                  />
                </div>
              </div>
              <div class="field">
                <label class="label has-text-white">E-Mail:</label>
                <div class="control">
                  <input
                    class="input"
                    type="email"
                    id="email"
                    autocomplete="email"
                    v-model="email"
                  />
                </div>
              </div>
              <div class="field">
                <label class="label has-text-white">Password:</label>
                <div class="control">
                  <input
                    class="input"
                    type="password"
                    id="password"
                    autocomplete="current-password"
                    v-model="password"
                  />
                </div>
              </div>
              <div class="field">
                <p v-if="getLoginState.status == 'fail'">
                  {{ getLoginState.error_message }}
                </p>
                <button
                  class="button is-dark is-fullwidth"
                  type="submit"
                >
                  Register
                </button>
              </div>
              <div class="field has-text-white pt-2">
                Already have an Account?
                <router-link to="/Login">Login</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="faded-footer">
      <div class="has-text-white" style="opacity: 0.7">
        <p>Images by Blizzard Entertainment™</p>
        <p>
          World of Warcraft, Warcraft and Blizzard Entertainment are trademarks
          or registered trademarks of Blizzard Entertainment, Inc. in the U.S.
          and/or other countries.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../store/index";
import { AUTH_REGISTER, AUTH_RESET } from "../../store/actions/auth";
import { USER_REQUEST } from "../../store/actions/user";
import { mapGetters } from "vuex";
import Loader from "../../components/Loader.vue";

export default {
  components: { Loader },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      loaded: true
    };
  },
  mounted: function () {

  },
  created: function () {
    this.$store.dispatch(AUTH_RESET);
  },
  computed: {
    ...mapGetters(["getLoginState"]),
  },
  methods: {
    register: function () {
      this.loaded = false;
      this.$store
        .dispatch(AUTH_REGISTER, {
          username: this.username,
          email: this.email,
          password: this.password,
        })
        .then(() => {
          
          this.$store.dispatch(USER_REQUEST).then((res) => {
            this.$router.push("/login");
          }).catch(() => {
            this.loaded = true;
          });
        }).catch(() => {
          this.loaded = true;
        });
    },
  },
};
</script>

<style lang="scss">
// div#app > nav.navbar {
//   display: none !important;
// }
</style>

