<template>
  <h3 class="headerBar has-text-white">
    {{ titleSpan }}
  </h3>
  <div v-if="listentrys">
    <table
      class="table is-charactertable is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <thead>
        <tr>
          <td class="has-text-white">Instanz</td>
          <td class="has-text-white" style="width: 200px">Ersteller</td>
          <td class="has-text-white" style="width: 150px">Startzeitpunkt</td>
          <td class="has-text-white" style="width: 100px">Status</td>
        </tr>
      </thead>
      <tbody>
        <tr
          class="group-list"
          v-for="list in listentrys"
          v-bind:key="list"
          v-bind:class="determineClass(list).class"
          @click="routeToList(list.id)"
        >
          <td>
            <img
              class="faction-image"
              v-bind:src="getFactionIcon(list.faction)"
            />
            <router-link
              v-bind:class="getFactionSpan(list.faction)"
              v-bind:to="`/list/${list.id}`"
            >
              {{ list.myinstance.title }}</router-link
            >
          </td>
          <td>{{ list.myuser.name }}</td>
          <td>
            {{ formatDate(list.running_time) }}
          </td>
          <td>
            <i
              v-bind:class="{
                'far fa-clock': determineClass(list).icon == 'over',
                'fas fa-lock': determineClass(list).icon == 'done',
                'fas fa-lock-open': determineClass(list).icon == '',
              }"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
    <nav
      class="pagination is-centered"
      role="navigation"
      aria-label="pagination"
      v-if="curPage"
    >
      <ul class="pagination-list">
        <li v-for="index in lastPage" :key="index" @click="findListFromParent(index)">
          <a
            class="button pagination-link is-dark"
            v-bind:class="{ 'has-text-highlight': index == curPage }"
            >{{ index }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
  <p class="has-text-highlight" v-else>
    Keine Listen gefunden.
  </p>
</template>


<script>
import moment from "moment";

import { mapGetters } from "vuex";

export default {
  name: "ListTable",
  data() {
    return {};
  },
  props: ["listentrys", "titleSpan", "curPage", "lastPage"],
  computed: {
    ...mapGetters(["isAuthenticated", "getUserData"]),
  },
  methods: {
    determineClass(obj){
      let $ret = {
        class: "",
        icon: ""
      }
      if(moment(new Date()).diff(moment(obj.running_time),'minute')>60){
        $ret.class = "is-over";
        $ret.icon = "over";
      }
      if(obj.closed_with_reputation != null){
        $ret.class = "is-done";
        $ret.icon = "done";
      }
      
      $ret.class = $ret.class + " " + this.getTRClass(obj.faction);
      return $ret;
    },
    routeToList(id){
        this.$router.push({ name: 'list', params: { id: id } });
    },
    getFactionIcon(faction) {
      return "/img/faction_" + faction.toLowerCase() + ".png";
    },
    getFactionSpan(faction) {
      return faction.toLowerCase() + "-span";
    },
    getTRClass(faction){
      return "is-"+faction.toLowerCase();
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    findListFromParent(page_num) {
      this.$emit('parent-list-finder', page_num);
    }
  },
};
</script>

<style lang="scss" scoped>
select optgroup {
  background: #131313;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  padding: 10px;
}

option {
  color: #ffffe8 !important;
  background-color: #333 !important;
}

.group-list > td:first-child {
  position: relative;
  padding-left: 42px;
  img {
position: absolute;
    width: 42px;
    top: -2px;
    left: -2px;
    padding: 5px;
  }
}
</style>