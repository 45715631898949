import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Register from "../views/Auth/Register.vue";
import Login from "../views/Auth/Login.vue";
import Forgot from "../views/Auth/Forgot.vue";
import List from "../views/List/List.vue";
import User from "../views/User/User.vue";
import New from "../views/List/New.vue";
import Character from "../views/Character/View.vue";
import Search from "../views/List/Search.vue";
import Settings from "../views/Auth/Settings.vue";

import Changelog from "../views/Info/Changelog.vue";
import Impressum from "../views/Info/Impressum.vue";
import PrivacyPolicy from "../views/Info/PrivacyPolicy.vue";

import store from '../store'
import { nextTick } from 'vue'

import auth from '../middleware/auth.js';
import guest from '../middleware/guest.js';


const DEFAULT_TITLE = 'WarcraftGroups';

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
        title: {
          name:  "Registrierung"
        },
      middleware: [
        guest
      ]
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {  
      title: {
        name:  "Login"
      },
      middleware: [
        guest
      ]
    }
  },
  
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: Forgot,
    meta: {  
      title: {
        name:  "Passwort vergessen"
      },
      middleware: [
        guest
      ]
    }
  },
  {
    path: "/new",
    name: "new",
    meta: {
      title: {
        name:  "Neu",
      },
      middleware: [
        auth
      ]
    },
    component: New
  },
  
  {
    path: "/list/search",
    name: "search",
    meta: {
      title: {
        name:  "Gruppensuche",
      },
    },
    component: Search
  },
  {
    path: "/list/:id",
    name: "list",
    component: List
  },
  {
    path: "/user/:id",
    name: "User",
    meta: {
      title: {
        name:  "Nutzer",
        noConcat: true
      },
    },
    component: User
  },
  {
    path: "/character/:realm/:charname",
    name: "Character",
    component: Character
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      title: {
        name:  "Einstellungen"
      },
      middleware: [
        auth
      ]
    }
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    meta: {  
      title: {
        name:  "Privacy Policy"
      },
    }
  },
  {
    path: "/impressum",
    component: Impressum,
    meta: {  
      title: {
        name:  "Impressum"
      },
      // metaTags: [
      //   {
      //     name: 'description',
      //     content: 'The home page of our example app.'
      //   },
      //   {
      //     property: 'og:description',
      //     content: 'The home page of our example app.'
      //   }
      // ]
    }
  },
  {
    path: "/changelog",
    component: Changelog,
    meta: {  
      title: {
        name:  "Changelog"
      },
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "is-extact-active",
})

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = (to.meta.title) ? ((!to.meta.title.noConcat) ? to.meta.title.name + " :: " + DEFAULT_TITLE : to.meta.title.name) : DEFAULT_TITLE;
  })
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context
  })
})


export default router
