<template>
  <div class="notfound-page" v-if="!meta && loaded">
    <h3 class="title is-3 has-text-white">
      Die Seite die du gesucht hast, wurde nicht gefunden.
    </h3>
    <div class="img-container">
      <img src="/img/404.png" alt="" />
    </div>
  </div>
  <div class="columns is-mobile is-centered" v-else-if="meta">
    <meta-patcher :title="meta.myinstance.title + (meta.keystone_level ? ' +'+meta.keystone_level : '')" :description="'Sign up now!'" />
    <div class="column is-8-desktop is-12-mobile is-10-tablet">
      <div class="row">
        <div class="columns list-columns">
          <div class="column mbg mbg-small">
            <i class="fas fa-hand-sparkles"></i>&nbsp;
            <span class="small is-muted">{{ meta.mode }}</span>
          </div>
          <div class="column mbg mbg-small">
            <i class="fas fa-hand-sparkles"></i>&nbsp;
            <span class="small is-muted" v-bind:class="factionSpan">{{
              meta.faction.toLowerCase()
            }}</span>
          </div>
          <div class="column mbg mbg-small">
            <i class="fas fa-clock"></i>&nbsp;
            <span class="small is-muted">{{ formattedDate }}</span>
          </div>
          <div
            class="column mbg mbg-small"
            v-if="getLoginId != meta.creator_id"
          >
            <i class="fas fa-user"></i>&nbsp;
            <span class="small is-muted">{{ meta.myuser.name }}</span>
          </div>

          <div class="column" v-if="getLoginId == meta.creator_id">
            <div class="level is-mobile">
              <div
                class="level-item"
                v-if="!meta.closed_with_reputation"
                @click="markListAsDone"
              >
                <button class="button is-outlined is-success is-fullwidth">
                  <span class="icon is-small">
                    <i class="fas fa-check"></i>
                  </span>
                  <span>Done</span>
                </button>
              </div>
              <div class="level-item" v-else>
                <span>
                  <span
                    v-if="meta.closed_with_reputation"
                    class="has-text-success"
                  >
                    <i class="fas fa-check"></i> <span>Gut gelaufen</span>
                  </span>
                  <span v-else class="has-text-danger">
                    <i class="fas fa-times"></i> <span>Gut gelaufen</span>
                    <span>Schlecht gelaufen</span>
                  </span>
                </span>
              </div>
              <div class="level-item">
                <button
                  class="button is-outlined is-danger is-fullwidth"
                  @click="markListAsDelete"
                >
                  <span class="icon is-small">
                    <i class="fas fa-times"></i>
                  </span>
                  <span>Löschen</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="row mbg" v-if="meta">
        <h2 class="title is-2 has-text-white" style="position: relative">
          <span
            >{{ meta.myinstance.title }}
            <small v-if="meta.keystone_level"
              >[ {{ meta.mode }} +{{ meta.keystone_level }} ]</small
            ><small v-else>[ {{ meta.mode }} ] </small>
          </span>
        </h2>
        <hr v-if="meta.description != null" />
        <div class="">
          {{ meta.description }}
        </div>
        <!-- <h4 class="title is-4 has-text-white" style="border-bottom: 1px solid #333;">Planed Encounters:</h4> -->
        <!-- <div class="col-md-12 buttons has-addons is-centered">
          <button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            Abyssal Commander Sivara</button
          ><button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            Blackwater Behemoth</button
          ><button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            Radiance of Azshara</button
          ><button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            Lady Ashvane</button
          ><button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            Orgozoa</button
          ><button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            The Queen's Court</button
          ><button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            Za'qul</button
          ><button
            class="button is-light is-small is-outlined"
            disabled="disabled"
          >
            Queen Azshara
          </button>
        </div> -->
      </div>
      <br />
      <div v-if="isAuthenticated && !alreadySignedUp">
        <div class="row mbg">
          <div class="columns">
            <div class="column is-8">
              <div class="container mbg-small selectedFilter">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label
                      class="label has-text-white is-bold has-text-highlight"
                      >Charakter:</label
                    >
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <div class="columns">
                          <div class="column">
                            <div class="select is-small is-fullwidth">
                              <select v-model="newSignup.charakter">
                                <option
                                  class="dropdown-item"
                                  v-for="char in available_characters"
                                  v-bind:key="char"
                                  v-bind:value="char.id"
                                  v-bind:data-class="char.myclass.name"
                                >
                                  {{ char.character }}-{{ char.realm }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="column">
                            <input
                              id="checkTank"
                              type="checkbox"
                              class="hidden highlight-button"
                              name="checkTank"
                              v-bind:checked="newSignup.roles.tank"
                              v-model="newSignup.roles.tank"
                            />
                            <label
                              class="button is-small is-light is-outlined"
                              for="checkTank"
                            >
                              <div class="entry-label">
                                <img
                                  src="/img/Encounter-journal-tank.png"
                                  alt=""
                                /><span>Tank</span>
                              </div></label
                            >&nbsp;
                            <input
                              id="checkHeal"
                              type="checkbox"
                              class="hidden highlight-button"
                              name="checkHeal"
                              v-bind:checked="newSignup.roles.heal"
                              v-model="newSignup.roles.heal"
                            />
                            <label
                              class="button is-small is-light is-outlined"
                              for="checkHeal"
                              ><div class="circle"></div>
                              <div class="entry-label">
                                <img
                                  src="/img/Encounter-journal-healer.png"
                                  alt=""
                                /><span>Heal</span>
                              </div></label
                            >&nbsp;
                            <input
                              id="checkDamage"
                              type="checkbox"
                              class="hidden highlight-button"
                              name="checkDamage"
                              v-bind:checked="newSignup.roles.damage"
                              v-model="newSignup.roles.damage"
                            />
                            <label
                              class="button is-small is-light is-outlined"
                              for="checkDamage"
                              ><div class="circle"></div>
                              <div class="entry-label">
                                <img
                                  src="/img/Encounter-journal-dps.png"
                                  alt=""
                                /><span>Damage</span>
                              </div></label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="container mbg-small selectedFilter">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label
                      class="label has-text-white is-bold has-text-highlight"
                      >Kommentar:</label
                    >
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input
                          type="text"
                          v-model="newSignup.kommentar"
                          class="input is-small"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field is-grouped is-grouped-right">
                  <div class="control">
                    <button
                      type="button"
                      class="button is-mbg"
                      @click="createSignUpList"
                    >
                      Beitreten
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <table
        class="table mbg is-charactertable is-narrow is-fullwidth charTable charTable_pause has-text-white"
        v-if="signups"
      >
        <thead>
          <tr>
            <th class="has-text-white">Character</th>
            <!-- <th
              class="col-xs-2 col-sm-2 col-md-2 col-lg-2 roleSort has-text-white"
            >
              Role <i class="fas fa-sort"></i>
            </th> -->
            <th class="has-text-white">Rollen</th>
            <th><button class="button is-small is-danger is-outlined is-pulled-right" v-on:click="exportString"><i class="fas fa-file-export"></i>&nbsp;&nbsp;ADDON EXPORT</button></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="signup in signups" v-bind:key="signup">
            <td>
              
              <img height="50"
                width="50"
                class="icon"
                 onerror="this.onerror=null;this.src='/img/character_placeholder.png';" v-bind:src="signup.character.avatar_image || '/img/character_placeholder.png'" alt="" />
              <img
                alt="faction"
                class="icon"
                v-bind:src="'/img/faction_' + signup.character.faction + '.png'"
                onerror="this.onerror=null;this.src='/favicon.png';"
                height="50"
                width="50"
              />
              <img
                class="class-icon"
                v-bind:src="
                  '/img/class/' +
                  getFormedSpan(signup.character.myclass.name) +
                  '.png'
                "
                onerror="this.onerror=null;this.src='/favicon.png';"
                height="50"
                width="50"
              />&nbsp;
              <span
                v-bind:class="
                  signup.character.myclass
                    ? 'class-' + getFormedSpan(signup.character.myclass.name)
                    : ''
                "
                class="dark-span"
                >{{ signup.character.character }}-{{
                  signup.character.realm
                }}</span
              >
              <small>({{ signup.character.avg_item_level }})</small>
            </td>
            <td>
              <img
                v-if="signup.is_damage"
                src="/img/Encounter-journal-dps.png"
                alt=""
              />
              <img
                v-if="signup.is_heal"
                src="/img/Encounter-journal-healer.png"
                alt=""
              />
              <img
                v-if="signup.is_tank"
                src="/img/Encounter-journal-tank.png"
                alt=""
              />
            </td>
            <td>
              <div class="field is-grouped is-grouped-right">
                <span class="ownBadge calendarEntry"
                  ><i
                    class="far fa-calendar"
                    v-bind:title="formatDate(signup.created_at)"
                  ></i
                ></span>
                <span class="ownBadge commentEntry" v-if="signup.kommentar"
                  ><i
                    class="far fa-comments"
                    v-bind:title="signup.kommentar"
                    @click="changeComment(signup)"
                  ></i
                ></span>
                <span
                  class="ownBadge removeEntry"
                  @click="removeSignUpList(signup.id)"
                  v-if="
                    getLoginId == signup.character.user_id ||
                    getLoginId == meta.creator_id
                  "
                  ><i class="fas fa-times"></i
                ></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import {
  SIGNUPS_CREATE,
  SIGNUPS_REMOVE,
  GET_LIST,
  SIGNUPS,
  WC_CHAR_ME,
  LIST,
  SIGNUPS_COMMENT,
  LIST_CLOSE,
  LIST_DELETE,
} from "../../utility/routes";
import { mapGetters } from "vuex";
import { lData } from "../../utility/db";
import { METHOD_DELETE, METHOD_POST } from "../../utility/globals";
import MetaPatcher from '../../components/MetaPatcher';

export default {
  components: { MetaPatcher },
  name: "List",
  data() {
    return {
      loaded: false,
      meta: null,
      signups: null,
      available_characters: null,
      signed_up: false,
      newSignup: {
        kommentar: "",
        charakter: null,
        spezialisierung: null,
        roles: {
          damage: null,
          heal: null,
          tank: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUserData", "getLoginId"]),
    formattedDate() {
      return this.formatDate(this.meta.running_time);
    },
    factionSpan() {
      return this.meta.faction.toLowerCase() + "-span";
    },
    alreadySignedUp() {
      if (typeof this.signups !== "object" || this.signups == null) {
        return false;
      }
      let $ret = false;
      let $isSignedUp = false;
      if (this.meta.unique_signups) {
        this.signups.forEach((element) => {
          if (element.character.user_id == this.getLoginId) {
            $isSignedUp = true;
          }
        });
      }

      return $isSignedUp;
    },
  },
  mounted: function () {
    this.loadMainData();
  },
  methods: {
    getFormedSpan(name) {
      return name.toLowerCase().replace(" ", "-");
    },
    formatDate(mDate) {
      return moment(mDate).format("DD.MM.YYYY HH:mm");
    },
    resetFormData() {
      this.newSignup = {
        kommentar: "",
        charakter: null,
        spezialisierung: null,
        roles: {
          damage: null,
          heal: null,
          tank: null,
        },
      };
    },
    getSignups() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, SIGNUPS, {
          list_id: this.$route.params.id,
        }).then((res) => {
          if (res.data.status == "success") {
            this.signups = res.data.response;
            resolve();
          }
        });
      });
    },
    getAvailableCharacters() {
      return !this.isAuthenticated
        ? false
        : new Promise((resolve, reject) => {
            lData(METHOD_POST, WC_CHAR_ME, {
              filter: {
                faction: this.meta.faction,
                list_id_exclude: this.$route.params.id,
              },
            }).then((res) => {
              if (res.data.status == "success") {
                this.available_characters = res.data.response;
                resolve();
              }
            });
          });
    },
    createSignUpList() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, SIGNUPS_CREATE, {
          list_id: this.$route.params.id,
          character_id: this.newSignup.charakter,
          kommentar: this.newSignup.kommentar,
          roles: this.newSignup.roles,
        }).then((res) => {
          if (res.data.status == "success") {
            this.signups = res.data.response;
            this.resetFormData();
            resolve();
          }
        });
      })
        .then(this.getSignups)
        .then(this.getAvailableCharacters);
    },
    loadMainData() {
      let listid = this.$route.params.id;
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, LIST, {
          list_id: listid,
        })
          .then((res) => {
            this.loaded = true;
            if (res.data.status == "success") {
              this.meta = res.data.response;
              resolve();
            }
          })
          .then(this.getSignups)
          .then(this.getAvailableCharacters);
      });
    },
    changeComment(signup) {
      if (
        signup.character.user_id == this.getLoginId ||
        this.meta.creator_id == this.getLoginId
      ) {
        this.$swal({
          title: `${signup.kommentar}`,
          input: "text",
          inputPlaceholder: "Kommentar",
          showCancelButton: true,
          confirmButtonText: "Speichern",
          showLoaderOnConfirm: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-dark",
            cancelButton: "button is-danger has-text-white",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            lData(METHOD_POST, SIGNUPS_COMMENT, {
              signup_id: signup.id,
              new_comment: result.value,
            }).then((res) => {
              if (res.data.status == "success") {
                signup.kommentar = result.value;
              }
            });
          }
        });
      } else {
        this.$swal(signup.kommentar);
      }
    },
    exportString(){
      let mFilter = this.signups.map((x) => [x.character.character,x.character.realm].join("-"));

        const textarea = document.createElement('textarea')
        textarea.value = mFilter.join(",");
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);
        textarea.select()

        try {
          var successful = document.execCommand('copy');
          this.copied = true
          this.$swal({
              title: "In die Zwischenablage kopiert",
              icon: "success",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
        } catch(err) {
          this.copied = false
            this.$swal({
              title: "Konnte nicht kopiert werden.",
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
        }

        textarea.remove()
    },
    markListAsDone() {
      let list_id = this.meta.id;
      this.$swal({
        title: `Wie ist die Gruppe gelaufen?`,
        showDenyButton: true,
        confirmButtonText: `Gut`,
        denyButtonText: `Nicht gut`,
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "button swal2-styled is-success is-outlined",
          denyButton: "button swal2-styled is-danger is-outlined",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (!result.isDismissed) {
          lData(METHOD_POST, LIST_CLOSE, {
            list_id: list_id,
            close_type: result.isConfirmed,
          }).then((res) => {
            this.loadMainData();
          });
        }
      });
    },
    markListAsDelete() {
      let list_id = this.meta.id;
      this.$swal({
        title: `Wirklich löschen?`,
        showCancelButton: true,
        confirmButtonText: "Löschen",
        cancelButtonText: "Nicht löschen",
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "button is-success is-outlined",
          cancelButton: "button is-danger is-outlined",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          lData(METHOD_POST, LIST_DELETE, {
            list_id: list_id,
          }).then((res) => {
            if (res.data.status == "success") {
              this.$router.push(`/`);
            }
          });
        }
      });
    },
    removeSignUpList(id) {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, SIGNUPS_REMOVE, {
          entry_id: id,
        }).then((res) => {
          if (res.data.status == "success") {
            this.signups = res.data.response;
            resolve();
          }
        });
      })
        .then(this.getSignups)
        .then(this.getAvailableCharacters);
    },
  },
};
</script>