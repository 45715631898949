<template>
  <div>
    <div class="columns">
      <div
        class="column is-8-desktop is-offset-2-desktop is-12-mobile is-10-tablet is-offset-1-tablet"
      >
        <div class="columns">
          <div class="column">
            <div class="container mbg mbg-small selectedFilter">
              <i
                class="fas fa-times close"
                v-if="selected.expansion"
                @click="removeExpansionSelection"
              ></i>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label has-text-white is-bold has-text-highlight"
                    >Expansion:</label
                  >
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <div
                        class="select is-small is-fullwidth"
                        v-if="expansions && !selected.expansion"
                      >
                        <select
                          v-model="selected.expansion"
                          @change="
                            getInstances();
                            findLists();
                          "
                        >
                          <option
                            class="dropdown-item"
                            v-for="expansion in expansions"
                            v-bind:key="expansion"
                            v-bind:value="expansion"
                          >
                            {{ expansion.title }}
                          </option>
                        </select>
                      </div>
                      <div v-else-if="selected.expansion">
                        <input
                          class="input is-small is-static has-text-white"
                          type="text"
                          readonly
                          v-model="selected.expansion.title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="container mbg mbg-small selectedFilter">
              <i
                class="fas fa-times close"
                v-if="selected.instance"
                @click="removeInstanceSelection"
              ></i>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label has-text-white is-bold has-text-highlight"
                    >Instance:</label
                  >
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <div
                        class="select is-small is-fullwidth"
                        v-if="instances && !selected.instance"
                      >
                        <select v-model="selected.instance" @change="findLists">
                          <optgroup
                            v-for="(instanceObj, name) in instances"
                            v-bind:label="name"
                            v-bind:key="(instanceObj, name)"
                          >
                            <option
                              class="dropdown-item"
                              v-for="instance in instanceObj"
                              v-bind:key="instance"
                              v-bind:value="instance"
                            >
                              {{ instance.title }}
                            </option>
                          </optgroup>
                        </select>
                      </div>
                      <div v-else-if="selected.instance">
                        <input
                          class="input is-small is-static has-text-white"
                          type="text"
                          readonly
                          v-model="selected.instance.title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mbg" v-if="foundLists">
          <table
            class="table is-charactertable is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          >
          <thead>
            <tr>
              <td class="has-text-white">Instanz</td>
              <td class="has-text-white" style="width: 200px;">Ersteller</td>
              <td class="has-text-white" style="width: 150px;">Startzeitpunkt</td>
              <td class="has-text-white" style="width: 100px;">Status</td>
            </tr>
          </thead>
            <tbody>
              <tr
                class="group-list"
                v-for="list in foundLists"
                v-bind:key="list"
                v-bind:class="determineClass(list).class"
                @click="routeToList(list.id)"
              >
                <td >
                  <img
                    class="faction-image"
                    v-bind:src="getFactionIcon(list.faction)"
                  />
                  <router-link v-bind:class="getFactionSpan(list.faction)"  v-bind:to="`/list/${list.id}`"> {{ list.myinstance.title }}</router-link>
                </td>
                <td>{{ list.myuser.name }}</td>
                <td>
                  {{ formatDate(list.running_time) }}
                </td>
                <td>
                  <i v-bind:class="{ 'far fa-clock': determineClass(list).icon == 'over', 'fas fa-lock': determineClass(list).icon == 'done', 'fas fa-lock-open':  determineClass(list).icon == ''}"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <nav
            class="pagination is-centered"
            role="navigation"
            aria-label="pagination"
            v-if="curPage"
          >
            <ul class="pagination-list">
              <li v-for="index in lastPage" :key="index" @click="findLists(index)">
                <a class="button pagination-link is-dark" v-bind:class="{ 'has-text-highlight': index == curPage }">{{ index }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import store from "../../store/index";
import {
  STATIC_EXPANSIONS,
  STATIC_INSTANCES,
  LIST_NEW,
  LIST,
} from "../../utility/routes";
// import { mapGetters } from "vuex";
import { lData } from "../../utility/db";
import { METHOD_POST } from "../../utility/globals";

export default {
  name: "Search",
  data() {
    return {
      expansions: null,
      instances: null,
      editForm: false,
      foundLists: null,
      selected: {
        expansion: null,
        instance: null,
      },
      lastPage: null,
      curPage: null,
    };
  },
  created: function () {
    this.getExpansions();
  },
  mounted: function () {
    this.findLists();
  },
  computed: {},
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getFactionIcon(faction) {
      return "/img/faction_" + faction.toLowerCase() + ".png";
    },
    getFactionSpan(faction) {
      return faction.toLowerCase() + "-span";
    },
    getTRClass(faction){
      return "is-"+faction.toLowerCase();
    },
    getExpansions() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, STATIC_EXPANSIONS).then((res) => {
          if (res.data.status == "success") {
            this.expansions = res.data.response;
          }
        });
      });
    },
    getInstances() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, STATIC_INSTANCES, {
          expansion_id: this.selected.expansion.id,
        }).then((res) => {
          if (res.data.status == "success") {
            this.instances = res.data.response;
          }
        });
      });
    },
    findLists(page_num) {
      let filterObj = {};

      if (this.selected.expansion != null) {
        filterObj.expansion = this.selected.expansion.id;
      }

      if (this.selected.instance != null) {
        filterObj.instance = this.selected.instance.id;
      }

      return new Promise((resolve, reject) => {
        lData(METHOD_POST, LIST, {
          list_id: 0,
          filter: filterObj,
          page: (typeof page_num !== 'undefined') ? page_num : 1
        }).then((res) => {
          if (res.data.status == "success") {
              if (res.data.response.total > 0) {
                  
                this.foundLists = res.data.response.data;
                if (this.foundLists.length > 0) {
                  this.curPage = res.data.response.current_page;
                  this.lastPage = res.data.response.last_page;
                }   
              }else{
                this.foundLists = null;
                this.curPage = null;
                  this.lastPage = null;
              }
          }
        });
      });
    },
    routeToList(id){
        this.$router.push({ name: 'list', params: { id: id } });
    },
    removeExpansionSelection() {
      this.selected.expansion = null;
      this.selected.instance = null;
      this.instances = null;
      this.lastPage = null;
      this.curPage = null;
      this.findLists();
    },
    removeInstanceSelection() {
      this.selected.instance = null;
      this.lastPage = null;
      this.curPage = null;
      this.findLists();
    },
    determineClass(obj){
      let $ret = {
        class: "",
        icon: ""
      }
      if(moment(new Date()).diff(moment(obj.running_time),'minute')>60){
        $ret.class = "is-over";
        $ret.icon = "over";
      }
      if(obj.closed_with_reputation != null){
        $ret.class = "is-done";
        $ret.icon = "done";
      }
      
      $ret.class = $ret.class + " " + this.getTRClass(obj.faction);
      return $ret;
    }
  },
};
</script>

<style lang="scss" scoped>
select optgroup {
  background: #131313;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  padding: 10px;
}

option {
  color: #ffffe8 !important;
  background-color: #333 !important;
}

.group-list > td:first-child {
  position: relative;
  padding-left: 42px;
  img {
position: absolute;
    width: 42px;
    top: -2px;
    left: -2px;
    padding: 5px;
  }
}
</style>