<template>
  <div>
    <div class="columns is-centered">
      <div class="column column is-7-desktop is-11-tablet is-12-mobile">
        <div style="text-align:center;" v-show="streamLoaded && streamIsOnline">
          <!-- <h2 class="title is-2 has-text-white">Unsere Featured-Streamer</h2> -->
          <div id="stream_promotion">
            <img
              class="image is-48x48 is-rounded"
              v-bind:src="featuredStreamer.picture"
              alt=""
            />&nbsp;
            <span class="title is-3 has-text-white">{{
              featuredStreamer.label
            }}</span>
          </div>
          <div id="dwidsch" ref="twitchVideo"></div>

          <hr />
        </div>
        <div v-if="!streamLoaded">
          <loader></loader>
        </div>
        <div class="box mbg mbg-medium">
          <article class="media">
            <div class="media-content">
              <div class="content has-text-white">
                <strong class="has-text-white"
                  >Warcraft<span class="has-text-pink">Groups</span></strong
                >
                <p style="text-align: justify; font-size: 85%">
                  WarcraftGroups ist eine <i>World of Warcraft</i> Seite in der
                  Gruppen, und sogar auch Hordler, Allianzen schmieden können um
                  gemeinsam mythische Schlüsselsteine zu spielen oder zu raiden.
                  Sei es die Weekly für den bestmöglichen Loot oder das
                  gemeinsame Raiden ohne die Befürchtung zu haben, dass sich die
                  Gruppen ohne Grund auflösen. Dir fehlt nur noch ein Key für
                  das Erreichen des Schlüsselsteinmeister-Erfolgs oder ein Raid
                  der auch Anfänger-freundlich ist? Erstell deine eigene Liste
                  oder tritt einer Liste bei. Triff gleichgesinnte Spieler die
                  auch mal bei einem oder mehreren Wipes das gemeinsame Ziel vor
                  Augen behalten werden.
                  <span
                    >Unsere Partner-Streamer helfen dir natürlich mit ihrer
                    Expertise weiter.</span
                  >
                </p>
              </div>
            </div>
          </article>
        </div>
        <h3 class="headerBar has-text-white">
          Aktive Spielersuchen
        </h3>
        <p class="has-text-highlight" v-if="Object.keys(lists).length === 0">
          Keine laufenden Listen gefunden.
        </p>
        <div class="columns is-multiline">
          <div class="column is-12-tablet is-12-mobile is-6-desktop" 
              v-for="solo in lists"
              v-bind:key="solo">
            <router-link
              v-bind:to="`/list/${solo.id}`"
              class="hoverObjectJumbotronHref"
            >
              <div class="subJumbotron">
                <div class="media-heading">
                  <img
                    class="faction-image"
                    v-bind:src="getFactionIcon(solo.faction)"
                  />
                  <div class="info">
                    <i class="fas fa-user-alt"></i>
                    {{ solo.myuser.name }}
                    <i class="far fa-calendar-alt"></i>
                    {{ formatDate(solo.running_time) }}
                  </div>
                  <!-- <span class="runtypeblock" style="float: right"
                ><i class="fas fa-skull-crossbones"></i> Rekill</span
              > -->
                </div>
              </div>
              <div
                class="jumbotron jumbotron-list jumbotron-list-raid hoverObjectJumbotron loading-small-tep"
              >
                <h2 class="has-text-white">
                  {{ solo.myinstance.title }}
                  <small>[ {{ solo.mode }} ] </small>
                </h2>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { FRONTPAGE } from "../utility/routes";
import { mapGetters } from "vuex";
import { lData } from "../utility/db";
import { METHOD_POST } from "../utility/globals";
import Loader from "../components/Loader.vue";

import Twitch from "../pub_library/twitch.v1.js";

export default {
  name: "Home",
  components: {
    Loader,
  },
  data() {
    return {
      player: null,
      lists: [],
      streamIsOnline: false,
      streamLoaded: false,
      featuredStreamer: {
        name: "pink_screen",
        label: "PinkScreen",
        picture:
          "https://static-cdn.jtvnw.net/jtv_user_pictures/pink_screen-profile_image-727b3760759153ce-70x70.jpeg",
      },
    };
  },
  mounted: function() {
    const options = {
      width: 600,
      height: 338,
      channel: this.featuredStreamer.name,
    };

    this.player = new Twitch.Player(this.$refs.twitchVideo, options);
    this.player.setVolume(0.5);

    this.player.addEventListener(Twitch.Player.READY, () => {
      this.streamLoaded = true;
    });

    this.player.addEventListener(Twitch.Player.PLAYING, () => {
      this.streamIsOnline = true;
    });

    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://player.twitch.tv/js/embed/v1.js')
    // document.head.appendChild(recaptchaScript)

    let listid = this.$route.params.id;
    return new Promise((resolve, reject) => {
      lData(METHOD_POST, FRONTPAGE, {
        list_id: listid,
      }).then((res) => {
        if (res.data.status == "success") {
          this.lists = res.data.response.data;
        }
      });
    });
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getFactionIcon(faction) {
      return "/img/faction_" + faction.toLowerCase() + ".png";
    },
    resizeIFrameToFitContent(iFrame) {
      iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
      iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
    },
  },
};
</script>

<style lang="scss">
.twitter-timeline {
  max-width: 100%;
  max-height: 1000px;
}
.fa {
  font-size: 25px;
  color: #ffffff;
}
.panel-title {
  font-size: 25px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
}
.panel-heading {
  background-color: #1da1f2 !important;
  color: #1da1f2 !important;
}
.panel {
  margin-top: 25px;
}
</style>
