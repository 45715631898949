import axios from "axios";
import Swal from 'sweetalert2';

export function lData(method, slug, payload) {
	return new Promise((resolve, reject) => {
		return axios[method](`${process.env.VUE_APP_API_ENDPOINT}${slug}`, payload).then((ret) => {
			resolve(ret);
		}).catch((err) => {
/*
			Swal.fire({
				title: err,
				icon: "error",
				toast: true,
				position: "top-end",
				showConfirmButton: false,
				timer: 1000,
				timerProgressBar: true,
			});
*/
			return axios[method](`${process.env.VUE_APP_API_ENDPOINT}${slug}`, payload).then((ret) => {
				resolve(ret);
			}).catch((err) => {
				reject(err);
			});
		});
	});
}