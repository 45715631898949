<template>
  <div>
    <h1 class="title is-1 has-text-white adsimple-311233151">
      Datenschutzerklärung
    </h1>
    <h2 class="title is-2 has-text-white adsimple-311233151">Datenschutz</h2>
    <p>
      Wir haben diese Datenschutzerklärung (Fassung 20.11.2020-311233151)
      verfasst, um Ihnen gemäß der Vorgaben der
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311233151"
        target="_blank"
        rel="noopener"
        >Datenschutz-Grundverordnung (EU) 2016/679</a
      >
      zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und
      welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
    </p>
    <p>
      Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr
      technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die
      wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
    </p>
    <h2 class="title is-2 has-text-white adsimple-311233151">
      Speicherung persönlicher Daten
    </h2>
    <p>
      Persönliche Daten, die Sie uns auf dieser Website elektronisch
      übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere
      persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
      Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der
      IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt
      und nicht an Dritte weitergegeben.
    </p>
    <p>
      Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
      jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung
      der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir
      geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch
      nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem
      Verhalten eingesehen werden.
    </p>
    <p>
      Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit abseits
      dieser Webseite &#8211; können wir keine sichere Übertragung und den
      Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
      niemals unverschlüsselt per E-Mail zu übermitteln.
    </p>
    <p>
      Die Rechtsgrundlage besteht nach
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311233151"
        target="_blank"
        rel="noopener"
        >Artikel 6  Absatz 1 a DSGVO</a
      >
      (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur
      Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen
      Einwilligung jederzeit widerrufen &#8211; eine formlose E-Mail reicht aus,
      Sie finden unsere Kontaktdaten im Impressum.
    </p>
    <h2 class="title is-2 has-text-white adsimple-311233151">
      Rechte laut Datenschutzgrundverordnung
    </h2>
    <p>
      Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende
      Rechte zu:
    </p>
    <ul class="adsimple-311233151">
      <li class="adsimple-311233151">
        Recht auf Berichtigung (Artikel 16 DSGVO)
      </li>
      <li class="adsimple-311233151">
        Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
      </li>
      <li class="adsimple-311233151">
        Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
      </li>
      <li class="adsimple-311233151">
        Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang
        mit der Berichtigung oder Löschung personenbezogener Daten oder der
        Einschränkung der Verarbeitung (Artikel 19 DSGVO)
      </li>
      <li class="adsimple-311233151">
        Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
      </li>
      <li class="adsimple-311233151">Widerspruchsrecht (Artikel 21 DSGVO)</li>
      <li class="adsimple-311233151">
        Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung
        — einschließlich Profiling — beruhenden Entscheidung unterworfen zu
        werden (Artikel 22 DSGVO)
      </li>
    </ul>
    <p>
      Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
      Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst
      in einer Weise verletzt worden sind, können Sie sich an die
      <a
        class="has-text-highlight adsimple-311233151 311233151"
        href="https://www.bfdi.bund.de"
        target="_blank"
        rel="noopener"
        >Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
        (BfDI)</a
      >
      wenden.
    </p>
    <h2 class="title is-2 has-text-white adsimple-311233151">
      TLS-Verschlüsselung mit https
    </h2>
    <p>
      Wir verwenden https um Daten abhörsicher im Internet zu übertragen
      (Datenschutz durch Technikgestaltung
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311233151"
        target="_blank"
        rel="noopener"
        >Artikel 25 Absatz 1 DSGVO</a
      >). Durch den Einsatz von TLS (Transport Layer Security), einem
      Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können
      wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die
      Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol
      links oben im Browser und der Verwendung des Schemas https (anstatt http)
      als Teil unserer Internetadresse.
    </p>
    <h2 class="title is-2 has-text-white adsimple-311233151">
      Google Fonts Datenschutzerklärung
    </h2>
    <p>
      Auf unserer Website verwenden wir Google Fonts. Das sind die
      &#8220;Google-Schriften&#8221; der Firma Google Inc. Für den europäischen
      Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow
      Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
    </p>
    <p>
      Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden
      bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem
      Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die
      Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert.
      Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt
      von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben,
      brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der
      Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst
      die Nutzung von CSS (Cascading Style Sheets) und der verwendeten
      Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung
      genau aussieht, werden wir uns noch im Detail ansehen.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Was sind Google Fonts?
    </h3>
    <p>
      Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800
      Schriftarten, die <a
        class="has-text-highlight adsimple-311233151"
        href="https://de.wikipedia.org/wiki/Google_LLC?tid=311233151"
        >Google</a
      > seinen Nutzern kostenlos zu Verfügung stellt.
    </p>
    <p>
      Viele dieser Schriftarten sind unter der SIL Open Font
      License veröffentlicht, während andere unter
      der Apache-Lizenz veröffentlicht wurden. Beides sind freie
      Software-Lizenzen.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Warum verwenden wir Google Fonts auf unserer Webseite?
    </h3>
    <p>
      Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen,
      doch müssen sie nicht auf unseren eigenen Server hochladen. Google Fonts
      ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
      halten. Alle Google-Schriften sind automatisch für das Web optimiert und
      dies spart Datenvolumen und ist speziell für die Verwendung mit mobilen
      Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die
      niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google
      Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering)
      in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können
      zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze
      Webseiten optisch verzerren. Dank des schnellen Content Delivery Networks
      (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme.
      Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla
      Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten
      modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS
      4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir
      unser gesamtes Online-Service so schön und einheitlich wie möglich
      darstellen können.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Welche Daten werden von Google gespeichert?
    </h3>
    <p>
      Wenn Sie unsere Webseite besuchen, werden die Schriften über einen
      Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an
      die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
      IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde
      entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten
      auf das zu reduzieren, was für eine ordentliche Bereitstellung von
      Schriften nötig ist. API steht übrigens für „Application Programming
      Interface“ und dient unter anderem als Datenübermittler im
      Softwarebereich.
    </p>
    <p>
      Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist
      somit geschützt. Durch die gesammelten Nutzungszahlen kann Google
      feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
      veröffentlicht Google auf internen Analyseseiten, wie beispielsweise
      Google Analytics. Zudem verwendet Google auch Daten des eigenen
      Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften
      verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts
      veröffentlicht. Unternehmer und Entwickler nützen das
      Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen
      zu können.
    </p>
    <p>
      Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch
      Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers,
      Bildschirmauflösung des Browsers und Name des Browsers automatisch an die
      Google-Server übertragen werden. Ob diese Daten auch gespeichert werden,
      ist nicht klar feststellbar bzw. wird von Google nicht eindeutig
      kommuniziert.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Wie lange und wo werden die Daten gespeichert?
    </h3>
    <p>
      Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen
      Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das
      ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu
      nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und
      schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
    </p>
    <p>
      Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt
      damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern.
      Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden
      sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf
      allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert
      Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von
      Sprache zu erhöhen und das Design zu verbessern.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
    </h3>
    <p>
      Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht
      einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an
      Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie
      den Google-Support auf
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://support.google.com/?hl=de&amp;tid=311233151"
        >https://support.google.com/?hl=de&amp;tid=311233151</a
      >
      kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie
      unsere Seite nicht besuchen.
    </p>
    <p>
      Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten
      Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an
      Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen.
      Mehr zu Google Fonts und weiteren Fragen finden Sie auf
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://developers.google.com/fonts/faq?tid=311233151"
        >https://developers.google.com/fonts/faq?tid=311233151</a
      >. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein,
      doch wirklich detaillierte Informationen über Datenspeicherung sind nicht
      enthalten. Es ist relativ schwierig, von Google wirklich präzise
      Informationen über gespeicherten Daten zu bekommen.
    </p>
    <p>
      Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten
      verwendet werden, können Sie auch auf
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://policies.google.com/privacy?hl=de&amp;tid=311233151"
        >https://www.google.com/intl/de/policies/privacy/</a
      >
      nachlesen.
    </p>
    <h2 class="title is-2 has-text-white adsimple-311233151">
      IONOS WebAnalytics Datenschutzerklärung
    </h2>
    <p>
      Wir nutzen auf unserer Website das Analyse-Tool IONOS WebAnalytics des
      deutschen Unternehmens 1&amp;1 IONOS SE, Elgendorfer Straße 57, 56410
      Montabaur, Deutschland. Das Tool hilft uns bei der Analyse unserer Website
      und dafür werden auch Daten erhoben und gespeichert. Allerdings verzichtet
      dieses Tool auf die Erhebung von Daten, die Sie als Person identifizieren
      könnten. Dennoch wollen wir Sie in dieser Datenschutzerklärung genauer
      über die Datenverarbeitung und Speicherung informieren und auch erklären,
      warum wir IONOS WebAnalytics nutzen.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Was ist IONOS WebAnalytics?
    </h3>
    <p>
      IONOS WebAnalytics ist, wie es der Name auch vermuten lässt, ein Tool, das
      der Analyse unserer Website dient. Das Softwareprogramm sammelt etwa
      Daten, wie lange Sie sich auf unserer Website befinden, welche Buttons Sie
      klicken oder von welcher anderen Websites Sie zu uns gefunden haben. So
      bekommen wir einen guten Überblick über das Userverhalten auf unserer
      Website. All diese Informationen sind anonym. Das bedeutet, dass wir durch
      diese Daten nicht Sie als Person identifizieren, sondern lediglich
      allgemeine Nutzungsinformationen und Statistiken erhalten.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Warum verwenden wir IONOS WebAnalytics auf unserer Website?
    </h3>
    <p>
      Unser Ziel ist es, Ihnen ein bestmögliches Erlebnis auf unserer Website zu
      bieten. Wir sind von unseren Angeboten überzeugt und wollen, dass unserer
      Website für Sie ein hilfreicher und nützlicher Ort ist. Dafür müssen wir
      unsere Website so gut wie möglich an Ihre Wünsche und Anliegen anpassen.
      Mit einem Webanalysetool wie IONOS WebAnalytics und den daraus
      resultierenden Daten können wir unsere Website dahingehend verbessern. Die
      Daten können uns weiters auch dienlich sein, Werbe- und Marketingmaßnahmen
      individueller zu gestalten. Bei all diesen Webanalysen liegt uns aber
      dennoch der Schutz personenbezogener Daten am Herzen. Entgegen anderen
      Analysetools speichert und verarbeitet IONOS WebAnalytics keine Daten, die
      Sie als Person erkennen könnten.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Welche Daten werden von IONOS WebAnalytics gespeichert?
    </h3>
    <p>
      Die Daten werden durch Logfiles oder durch einen sogenannten Pixel erhoben
      und gespeichert. Ein Pixel ist ein Ausschnitt aus JavaScript-Code, der
      eine Ansammlung von Funktionen lädt, mit dem man Userverhalten verfolgen
      kann. WebAnalytics verzichtet bewusst auf die Verwendung von Cookies.
    </p>
    <p>
      IONOS speichert keine personenbezogenen Daten von Ihnen. Bei der
      Übermittlung eines Seitenaufrufes wird zwar Ihre IP-Adresse übertragen,
      allerdings dann sofort anonymisiert und so verarbeitet, dass man Sie als
      Person nicht identifizieren kann.
    </p>
    <p>Folgenden Daten werden von IONOS WebAnalytics gespeichert:</p>
    <ul class="adsimple-311233151">
      <li class="adsimple-311233151">Ihr Browsertyp und Ihre Browserversion</li>
      <li class="adsimple-311233151">
        welche Website Sie zuvor besucht haben (Referrer)
      </li>
      <li class="adsimple-311233151">
        welche spezifische Webseite Sie bei uns aufgerufen haben
      </li>
      <li class="adsimple-311233151">welches Betriebssystem Sie nutzen</li>
      <li class="adsimple-311233151">
        welches Endgerät Sie verwenden (PC, Tablet oder Smartphone)
      </li>
      <li class="adsimple-311233151">
        wann Sie auf unsere Seite gekommen sind
      </li>
      <li class="adsimple-311233151">Ihre IP-Adresse in anonymisierter Form</li>
    </ul>
    <p>
      Die Daten werden an keine Drittanbieter weitergegeben und nur für
      statistische Auswertungen genutzt.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Wie lange und wo werden die Daten gespeichert?
    </h3>
    <p>
      Die Daten werden solange gespeichert, bis der Vertrag zwischen IONOS
      WebAnalytics und uns ausläuft. Die Daten werden im Falle eines regulären
      Webhosting-Tarifs in unserem Log-Verzeichnis gespeichert und daraus
      grafische Statistiken erzeugt. Diese Logs werden alle 8 Wochen gelöscht.
      Im Falle eines MyWebsite-Tarifs werden die Daten über einen Pixel
      ermittelt. Hier werden die Daten nur innerhalb der IONOS WebAnalytics
      gespeichert und verarbeitet.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
    </h3>
    <p>
      Grundsätzlich haben Sie jederzeit das Recht auf Auskunft, Berichtigung
      bzw. Löschung und Einschränkung der Verarbeitung Ihrer personenbezogenen
      Daten. Sie können zudem auch jederzeit die Einwilligung zur Verarbeitung
      der Daten widerrufen. Da über IONOS WebAnalytics allerdings keine
      personenbezogenen Daten gespeichert oder verarbeitet werden und somit eine
      Zuordnung von Ihnen als Person nicht möglich ist, gibt es auch die
      Möglichkeit solche Daten zu löschen nicht.
    </p>
    <p>
      Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die
      wirklich sparsame Datenverarbeitung von IONOS WebAnalytics näherbringen.
      Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir
      Ihnen die Datenschutzerklärung des Unternehmens unter
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://www.ionos.de/hilfe/datenschutz/datenverarbeitung-von-webseitenbesuchern-ihres-11-ionos-produktes/webanalytics/"
        target="_blank"
        rel="noopener noreferrer"
        >ionos webanalytics</a
      >.
    </p>
    <h2 class="title is-2 has-text-white adsimple-311233151">
      Google reCAPTCHA Datenschutzerklärung
    </h2>
    <p>
      Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns
      bestmöglich zu sichern und zu schützen. Um das zu gewährleisten, verwenden
      wir Google reCAPTCHA der Firma Google Inc. Für den europäischen Raum ist
      das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin
      4, Irland) für alle Google-Dienste verantwortlich. Mit reCAPTCHA können
      wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch und Blut sind
      und kein Roboter oder eine andere Spam-Software. Unter Spam verstehen wir
      jede, auf elektronischen Weg, unerwünschte Information, die uns
      ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur
      Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von Google
      müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier reicht es
      in den meisten Fällen, wenn Sie einfach ein Häkchen setzen und so
      bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible reCAPTCHA
      Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie das genau
      funktioniert und vor allem welche Daten dafür verwendet werden, erfahren
      Sie im Verlauf dieser Datenschutzerklärung.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Was ist reCAPTCHA?
    </h3>
    <p>
      reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor
      Spam-Software und den Missbrauch durch nicht-menschliche Besucher schützt.
      Am häufigsten wird dieser Dienst verwendet, wenn Sie Formulare im Internet
      ausfüllen. Ein Captcha-Dienst ist eine Art automatischer Turing-Test, der
      sicherstellen soll, dass eine Handlung im Internet von einem Menschen und
      nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt
      nach dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung
      zwischen Bot und Mensch fest. Bei Captchas übernimmt das auch der Computer
      bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen
      Aufgaben, die für Menschen leicht zu lösen sind, doch für Maschinen
      erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine
      Rätsel mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen
      von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich bin
      kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst das nicht
      mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext
      eingebunden und dann läuft das Tool im Hintergrund und analysiert Ihr
      Benutzerverhalten. Aus diesen Useraktionen berechnet die Software einen
      sogenannten Captcha-Score. Google berechnet mit diesem Score schon vor der
      Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Sie ein Mensch
      sind. reCAPTCHA bzw. Captchas im Allgemeinen kommen immer dann zum
      Einsatz, wenn Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen
      usw.) manipulieren oder missbrauchen könnten.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Warum verwenden wir reCAPTCHA auf unserer Webseite?
    </h3>
    <p>
      Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen.
      Bots oder Spam-Software unterschiedlichster Art dürfen getrost zuhause
      bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu schützen und die
      bestmögliche Benutzerfreundlichkeit für Sie anzubieten. Aus diesem Grund
      verwenden wir Google reCAPTCHA der Firma Google. So können wir uns
      ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben. Durch die
      Verwendung von reCAPTCHA werden Daten an Google übermittelt, um
      festzustellen, ob Sie auch wirklich ein Mensch sind. reCAPTCHA dient also
      der Sicherheit unserer Webseite und in weiterer Folge damit auch Ihrer
      Sicherheit. Zum Beispiel könnte es ohne reCAPTCHA passieren, dass bei
      einer Registrierung ein Bot möglichst viele E-Mail-Adressen registriert,
      um im Anschluss Foren oder Blogs mit unerwünschten Werbeinhalten
      „zuzuspamen“. Mit reCAPTCHA können wir solche Botangriffe vermeiden.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Welche Daten werden von reCAPTCHA gespeichert?
    </h3>
    <p>
      reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob
      die Handlungen auf unserer Webseite auch wirklich von Menschen stammen. Es
      kann also die IP-Adresse und andere Daten, die Google für den
      reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen werden
      innerhalb der Mitgliedstaaten der EU oder anderer Vertragsstaaten des
      Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt,
      bevor die Daten auf einem Server in den USA landen. Die IP-Adresse wird
      nicht mit anderen Daten von Google kombiniert, sofern Sie nicht während
      der Verwendung von reCAPTCHA mit Ihrem Google-Konto angemeldet sind.
      Zuerst prüft der reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon
      Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.) platziert
      sind. Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem
      Browser und erfasst einen Schnappschuss Ihres Browserfensters.
    </p>
    <p>
      Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den
      Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von Daten, die
      nach unserer Erkenntnis, von Google verarbeitet werden.
    </p>
    <ul class="adsimple-311233151">
      <li class="adsimple-311233151">
        Referrer URL (die Adresse der Seite von der der Besucher kommt)
      </li>
      <li class="adsimple-311233151">IP-Adresse (z.B. 256.123.123.1)</li>
      <li class="adsimple-311233151">
        Infos über das Betriebssystem (die Software, die den Betrieb Ihres
        Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X
        oder Linux)
      </li>
      <li class="adsimple-311233151">
        Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
      </li>
      <li class="adsimple-311233151">
        Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der
        Tastatur ausführen wird gespeichert)
      </li>
      <li class="adsimple-311233151">
        Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf
        Ihrem PC voreingestellt haben wird gespeichert)
      </li>
      <li class="adsimple-311233151">
        Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die
        Webseiten ermöglicht, sich an den User anzupassen. JavaScript-Objekte
        können alle möglichen Daten unter einem Namen sammeln)
      </li>
      <li class="adsimple-311233151">
        Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung
        besteht)
      </li>
    </ul>
    <p>
      Unumstritten ist, dass Google diese Daten verwendet und analysiert noch
      bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der
      Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der ganze
      Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche Daten
      Google genau speichert, erfährt man von Google nicht im Detail.
    </p>
    <p>
      Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir uns
      auf die reCAPTCHA Demo-Version von Google unter
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://www.google.com/recaptcha/api2/demo"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.google.com/recaptcha/api2/demo</a
      >. All diese Cookies benötigen zu Trackingzwecken eine eindeutige Kennung.
      Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der Demo-Version
      gesetzt hat:
    </p>
    <p>
      <strong class="has-text-white adsimple-311233151">Name:</strong> IDE<br />
      <strong class="has-text-white adsimple-311233151">Wert:</strong
      > WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-311233151-8<br />
      <strong class="has-text-white adsimple-311233151">Verwendungszweck:</strong> Dieses
      Cookie wird von der Firma DoubleClick (gehört auch Google) gesetzt, um die
      Aktionen eines Users auf der Webseite im Umgang mit Werbeanzeigen zu
      registrieren und zu melden. So kann die Werbewirksamkeit gemessen und
      entsprechende Optimierungsmaßnahmen getroffen werden. IDE wird in Browsern
      unter der Domain doubleclick.net gespeichert.<br />
      <strong class="has-text-white adsimple-311233151">Ablaufdatum:</strong> nach einem Jahr
    </p>
    <p>
      <strong class="has-text-white adsimple-311233151">Name:</strong> 1P_JAR<br />
      <strong class="has-text-white adsimple-311233151">Wert:</strong> 2019-5-14-12<br />
      <strong class="has-text-white adsimple-311233151">Verwendungszweck:</strong> Dieses
      Cookie sammelt Statistiken zur Webseite-Nutzung und misst Conversions.
      Eine Conversion entsteht z.B., wenn ein User zu einem Käufer wird. Das
      Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden.
      Weiters kann man mit dem Cookie vermeiden, dass ein User dieselbe Anzeige
      mehr als einmal zu Gesicht bekommt.<br />
      <strong class="has-text-white adsimple-311233151">Ablaufdatum:</strong> nach einem Monat
    </p>
    <p>
      <strong class="has-text-white adsimple-311233151">Name:</strong> ANID<br />
      <strong class="has-text-white adsimple-311233151">Wert:</strong
      > U7j1v3dZa3112331510xgZFmiqWppRWKOr<br />
      <strong class="has-text-white adsimple-311233151">Verwendungszweck:</strong> Viele Infos
      konnten wir über dieses Cookie nicht in Erfahrung bringen. In der
      Datenschutzerklärung von Google wird das Cookie im Zusammenhang mit
      „Werbecookies“ wie z. B. &#8220;DSID&#8221;, &#8220;FLC&#8221;,
      &#8220;AID&#8221;, &#8220;TAID&#8221; erwähnt. ANID wird unter Domain
      google.com gespeichert.<br />
      <strong class="has-text-white adsimple-311233151">Ablaufdatum:</strong> nach 9 Monaten
    </p>
    <p>
      <strong class="has-text-white adsimple-311233151">Name:</strong> CONSENT<br />
      <strong class="has-text-white adsimple-311233151">Wert: </strong
      >YES+AT.de+20150628-20-0<br />
      <strong class="has-text-white adsimple-311233151">Verwendungszweck:</strong> Das Cookie
      speichert den Status der Zustimmung eines Users zur Nutzung
      unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit,
      um User zu überprüfen, Betrügereien von Anmeldeinformationen zu verhindern
      und Userdaten vor unbefugten Angriffen zu schützen.<br />
      <strong class="has-text-white adsimple-311233151">Ablaufdatum:</strong> nach 19 Jahren
    </p>
    <p>
      <strong class="has-text-white adsimple-311233151">Name:</strong> NID<br />
      <strong class="has-text-white adsimple-311233151">Wert:</strong>
      0WmuWqy311233151zILzqV_nmt3sDXwPeM5Q<br />
      <strong class="has-text-white adsimple-311233151">Verwendungszweck:</strong> NID wird von
      Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit
      Hilfe des Cookies „erinnert“ sich Google an Ihre meist eingegebenen
      Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie
      immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige
      ID, um persönliche Einstellungen des Users für Werbezwecke zu sammeln.<br />
      <strong class="has-text-white adsimple-311233151">Ablaufdatum:</strong> nach 6 Monaten
    </p>
    <p>
      <strong class="has-text-white adsimple-311233151">Name:</strong> DV<br />
      <strong class="has-text-white adsimple-311233151">Wert:</strong>
      gEAABBCjJMXcI0dSAAAANbqc311233151-4<br />
      <strong class="has-text-white adsimple-311233151">Verwendungszweck:</strong> Sobald Sie
      das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird dieses Cookie
      gesetzt. Das Cookie wird von Google Analytics für personalisierte Werbung
      verwendet. DV sammelt Informationen in anonymisierter Form und wird
      weiters benutzt, um User-Unterscheidungen zu treffen.<br />
      <strong class="has-text-white adsimple-311233151">Ablaufdatum:</strong> nach 10 Minuten
    </p>
    <p>
      <strong class="has-text-white adsimple-311233151">Anmerkung: </strong>Diese Aufzählung
      kann keinen Anspruch auf Vollständigkeit erheben, da Google
      erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Wie lange und wo werden die Daten gespeichert?
    </h3>
    <p>
      Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den
      Google-Server übertragen. Wo genau diese Daten gespeichert werden, stellt
      Google, selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine
      Bestätigung von Google erhalten zu haben, ist davon auszugehen, dass Daten
      wie Mausinteraktion, Verweildauer auf der Webseite oder
      Spracheinstellungen auf den europäischen oder amerikanischen
      Google-Servern gespeichert werden. Die IP-Adresse, die Ihr Browser an
      Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus
      weiteren Google-Diensten zusammengeführt. Wenn Sie allerdings während der
      Nutzung des reCAPTCHA-Plug-ins bei Ihrem Google-Konto angemeldet sind,
      werden die Daten zusammengeführt.<strong class="has-text-white adsimple-311233151"
        > </strong
      >Dafür gelten die abweichenden Datenschutzbestimmungen der Firma Google.
    </p>
    <h3 class="title is-3 has-text-white adsimple-311233151">
      Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
    </h3>
    <p>
      Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an
      Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite
      besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen
      ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die Daten
      sobald Sie unsere Seite aufrufen automatisch an Google übermittelt. Um
      diese Daten wieder zu löschen, müssen Sie den Google-Support auf 
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://support.google.com/?hl=de&amp;tid=311233151"
        target="_blank"
        rel="noopener"
        >https://support.google.com/?hl=de&amp;tid=311233151</a
      >
      kontaktieren.
    </p>
    <p>
      Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden,
      dass Google LLC und deren Vertreter automatisch Daten erheben, bearbeiten
      und nutzen.
    </p>
    <p>
      Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von
      Google auf
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://developers.google.com/recaptcha/"
        target="_blank"
        rel="noopener noreferrer"
        >https://developers.google.com/recaptcha/</a
      >. Google geht hier zwar auf die technische Entwicklung der reCAPTCHA
      näher ein, doch genaue Informationen über Datenspeicherung und
      datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute
      Übersicht über die grundsätzliche Verwendung von Daten bei Google finden
      Sie in der hauseigenen Datenschutzerklärung auf
      <a
        class="has-text-highlight adsimple-311233151"
        href="https://policies.google.com/privacy?hl=de&amp;tid=311233151"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.google.com/intl/de/policies/privacy/</a
      >.
    </p>
    <p style="margin-top: 15px">
      Quelle: Erstellt mit dem <a
      class="has-text-highlight"
        title="Datenschutz Generator Deutschland"
        href="https://www.adsimple.de/datenschutz-generator/"
        target="_blank"
        rel="follow"
        style="text-decoration: none"
        >Datenschutz Generator</a
      >
      von AdSimple in Kooperation mit
      <a class="has-text-highlight" href="https://www.fashiongott.de" target="_blank" rel="follow" title=""
        >fashiongott.de</a
      >
    </p>
  </div>
</template>



<script>
export default {
  name: "PrivacyPolicy",
};
</script>