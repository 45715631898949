import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';




Axios.defaults.headers.common['Authorization'] = `${store.state.auth.token}`;

createApp(App).use(store).use(router).use(store).use(VueSweetalert2).mount('#app')
