<template>
  <span />
</template>
<script>
import { onMounted } from "vue";

const patchMeta = ({ title, description, previewUrl, shareUrl, siteName }) => {
  // Patch title
  const [titleEl] = document.getElementsByTagName("title");
  titleEl.textContent = `${siteName} :: ${title} - ${description}`;

  // Patch META
  Array.from(document.getElementsByClassName("APP_META")).forEach((el) => {
    el.remove();
  });
  const APP_META = `<meta class="APP_META" property="og:title" content="${title}">
    <meta class="APP_META" property="og:description" content="${description}">
    <meta class="APP_META" property="og:image" content="${previewUrl}">
    <meta class="APP_META" property="og:url" content="${
      shareUrl || location.href
    }">
    <meta class="APP_META" name="twitter:card" content="summary_large_image">
    <meta class="APP_META" property="og:site_name" content="${siteName}">`;
  const [headEl] = document.getElementsByTagName("head");
  if (!headEl) return;
  headEl.innerHTML = `${headEl.innerHTML}${APP_META}`;
};

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "Find your groups quick & easy!",
    },
    previewUrl: {
      type: String,
      default: "/WG_logo_big.svg",
    },
    shareUrl: String,
    siteName: {
      type: String,
      default: "WarcraftGroups",
    },
  },
  setup(props) {
    onMounted(async () => {
      patchMeta(props);
    });
  },
};
</script>