<template>
  <div v-if="!loaded">
    <loader></loader>
  </div>
  <div v-else>
    <div ref="mainContainer" class="container">
      <div class="columns">
        <div class="column is-12-mobile">
          <div v-if="character">
            <h1 class="title is-1 has-text-centered has-text-white">
              {{ character.character }}-{{ character.realm }}
            </h1>
            <div class="row mbg" v-if="character.equipment.length > 0">
             
              <div class="character_screen columns">
                <div class="left_items column is-1">
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'HEAD')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'NECK')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'SHOULDER')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'BACK')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'CHEST')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'TABARD')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'SHIRT')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'WRIST')"
                    ></EQItem>
                  </div>
                </div>
                <div class="middle">
                  <img v-bind:src="character.render_image" alt="" />
                  <div class="items">
                    <EQItem
                      :itemObject="
                        getItemIcon(character.equipment, 'MAIN_HAND')
                      "
                    ></EQItem>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'OFF_HAND')"
                    ></EQItem>
                  </div>
                </div>
                <div class="right_items column is-1">
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'HANDS')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'WAIST')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'LEGS')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'FEET')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'FINGER_1')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="getItemIcon(character.equipment, 'FINGER_2')"
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="
                        getItemIcon(character.equipment, 'TRINKET_1')
                      "
                    ></EQItem>
                  </div>
                  <div>
                    <EQItem
                      :itemObject="
                        getItemIcon(character.equipment, 'TRINKET_2')
                      "
                    ></EQItem>
                  </div>
                </div>
              </div>
            </div>
            <p v-else class="notification is-danger">
              Noch kein Equipment gefunden.
              <br />
              Update den Charakter um Informationen zu laden.
            </p>
            <h1 class="title has-text-centered has-text-white">Progress</h1>
            <div>
              <p class="notification is-danger" v-if="character.progress.length == 0">

                Noch kein Progress gefunden.
                <br />
                Update den Charakter um Informationen zu laden.
              </p>
              <div v-for="p in sortedProgress" v-bind:key="p">
                <h2 class="title is-2 has-text-white">
                  {{ p.title }}
                </h2>
                <div class="raidOverview">
                  <div
                    v-for="modeObj in p.mode"
                    v-bind:key="modeObj"
                    class="row mbg"
                  >
                    <h3 class="title is-3 has-text-white">
                      {{ modeObj.name }}
                      <small class="has-text-danger" v
                        >{{ modeObj.completed_count }} /
                        {{ modeObj.total_count }}</small
                      >
                    </h3>
                    <div
                      v-for="boss in modeObj.bosses"
                      v-bind:key="boss"
                      v-bind:class="{
                        'has-text-success': boss.completed_count > 0,
                      }"
                    >
                      {{ boss.name }} <span v-if="boss.completed_count > 0">x {{ boss.completed_count }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row mbg">
            Der Charakter <b><i class="has-text-warning">{{charname.c}}-{{charname.r}}</i></b> konnte nicht in unserer Datenbank gefunden werden.
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import store from "../../store/index";
import { WC_CHAR_SPECIFIC } from "../../utility/routes";
import { mapGetters } from "vuex";
import { lData } from "../../utility/db";
import { METHOD_POST, METHOD_GET, METHOD_DELETE } from "../../utility/globals";
import Loader from "../../components/Loader.vue";
import EQItem from "../../components/EQItem.vue";

export default {
  components: { Loader, EQItem },
  name: "Settings",
  data() {
    return {
      loaded: false,
      character: null,
      expansion_data: null,
    };
  },
  computed: {
    charname(){
      return {c: this.$route.params.charname, r: this.$route.params.realm}
    },
    sortedProgress() {
      let $e = [];

      this.expansion_data.forEach((element) => {
        let DUNGEON = {};
        DUNGEON.title = element.title;
        DUNGEON.picture = element.picture;

        DUNGEON.mode = [];
        this.modeList(element.modes).forEach((mode) => {
          let modeBobble = {};
          modeBobble.name = mode;
          modeBobble.id = element.id;
          modeBobble.bosses = [];
          modeBobble.total_count = element.bosses.length;
          modeBobble.completed_count = 0;

          element.bosses.forEach((boss) => {
            let BossObject = {};
            BossObject.name = boss.name;
            BossObject.id = boss.id;
            BossObject.completed_count = 0;
            this.character.progress.forEach((ob) => {
              if (ob.instance.id == element.id) {
                ob.progress.forEach((charProgress) => {
                  if (charProgress.difficulty.name == mode) {
                    boss.modes.forEach((bossMode) => {
                      if (bossMode.name == mode) {
                        modeBobble.completed_count =
                          charProgress.progress.completed_count;
                        charProgress.progress.encounters.forEach((enc) => {
                          if (boss.name == enc.encounter.name) {
                            BossObject.completed_count = enc.completed_count;
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
            modeBobble.bosses.push(BossObject);
          });

          DUNGEON.mode.push(modeBobble);
        });
        $e.push(DUNGEON);
      });
      return $e;
    },
  },
  created: function () {
    if (this.$route.params.charname && this.$route.params.realm) {
      this.loadCharacters().then(() => {
        this.loaded = true;
      });
    }
  },
  mounted: function () {},
  methods: {
    getItemIcon($obj, $selector) {
      let $filter = $obj.find((e) => e.slot == $selector);
      return {item: $filter, slot: $selector};
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    modeList: function (modes) {
      return modes.split("|");
    },
    loadCharacters() {
      return new Promise((resolve, reject) => {
        lData(
          METHOD_GET,
          WC_CHAR_SPECIFIC +
            this.$route.params.realm +
            "/" +
            this.$route.params.charname
        )
          .then((res) => {
            if (res.data.status == "success") {
              this.character = res.data.response;
              this.expansion_data = res.data.expansion_data;
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    // addUpdateQue(character) {
    //   return new Promise((resolve, reject) => {
    //     lData(METHOD_POST, WC_CHAR_UPDATE, {
    //       character_id: character.id,
    //     })
    //       .then((res) => {
    //         if (res.data.status == "success") {
    //           this.loadCharacters();
    //           resolve();
    //         }
    //       })
    //       .catch(() => {
    //         reject();
    //       });
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.bnet-button {
  background: #1a84ea;
  border-color: #1a84ea;
}
.bnet-button:hover {
  background: #0876dd;
}
.bordered-h1 {
  border-bottom: 1px solid #333;
}

div.imageinfo {
  position: absolute;
  span.img {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.625rem;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    border-radius: 0.25rem;
    line-height: 1;
    color: #fff;
    margin-right: 0.5rem;
    position: relative;
  }
}

.media-heading-long .info {
  margin-left: 60px;
}
</style>