<template>
  <app-header v-if="!isLoginRegisterForm"></app-header>
  <div id="v-body" v-bind:class="{ 'is-fullscreen': isLoginRegisterForm}">
    <!-- <app-header v-if="!isLoginRegisterForm"></app-header> -->
    
    <div class="note note-battlenet" v-if="!getBnetObject">Du hast bisher noch kein BattleNet-Account verknüpft. Hole das <router-link to="/settings">hier</router-link> nach.{{getBnetObject}}</div>
    <div class="note note-character" v-if="getOutdatedArray > 0">Du besitzt veraltetete öffentliche Charaktere. Update diese <router-link to="/settings">hier</router-link>.</div>
    <div class="wcg-full-height has-text-white">
      <div
        class="wcg-center"
        v-bind:class="{ 'wcg-center-low-opacity': !isLoginRegisterForm }"
      >
        <div class="wcg-blur">
          <div class="wcg-background"></div>
        </div>
      </div>
      <router-view :key="$route.fullPath" class="wcg-main content" /> 
    </div> 
    <a id="to-the-top" href="javascript:document.getElementById('v-body').scrollIntoView(true);" v-if="scrollpx > 200" v-bind:style="opacity"><i class="fas fa-arrow-alt-circle-up"></i></a>
  </div>
  <footer class="footer" v-bind:class="{ 'is-fullscreen': isLoginRegisterForm}">
    <div class="content has-text-centered">
      <p class="has-text-white">
        <strong class="has-text-white"
          >Warcraft<i class="has-text-pink">Groups</i></strong
        >
        by <a class="has-text-highlight" href="https://github.com/Machigatta"><i class="fab fa-github"></i> Machigatta</a> <span class="has-text-grey-light"><i class="fab fa-discord"></i> Ravenstorm#1191</span>
        <br />
        <router-link class="has-text-highlight" to="/privacy-policy#" exact
          >Privacy Policy</router-link
        > | <router-link class="has-text-highlight" to="/impressum" exact
          >Impressum</router-link
        > | <router-link class="has-text-highlight" to="/changelog" exact
          >Changelog</router-link
        >
      </p>
    </div>
  </footer>
</template>


<script>
import "../node_modules/bulma/bulma.sass";
import "./assets/style.scss";
import "./assets/class_colors.css";
import Header from "./components/Header";
import { USER_REQUEST } from "./store/actions/user";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      localRoute: this.$router.currentRoute,
      isLoginRegisterForm: false,
      scrollpx: 0
    };
  },
  methods: {
    handleScroll() {
      this.scrollpx = window.scrollY;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getCharArray", "getBnetObject", "getOutdatedArray"]),
    opacity: function() {
      return "opacity:"+(0.2 + this.scrollpx/1000);
    },
    oldBoys: function(){
      return false;
    }
  },
  mounted: function () {
    if (this.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  },
  watch: {
    localRoute: function (newRoute, oldRoute) {
      this.isLoginRegisterForm =
        newRoute.name == "login" || newRoute.name == "register" || newRoute.name == "forgot-password";
    },
  },
  components: {
    "app-header": Header,
  },
};
</script>