const USER = '/user';
const USER_ME = USER + '/me';
const USER_ROLE = USER + '/roles';
const USER_LISTS = USER + '/lists';
const USER_SIGNUPS = USER + '/signups';
const USER_NOTIFICATIONS = USER + '/notifications';
const USER_NOTIFICATION_SINGLE = USER + '/notification';
const USER_MESSAGES = USER + '/messages';

const _LOGIN = '/auth/login';
const _REGISTER = '/auth/register';
const _FORGOTPASSWORD = '/auth/forgot-password';
const _RESETPASSWORD = '/auth/reset-password';

const BNET_UPDATE_CODE = '/bnet/update';
const BNET_GET = '/bnet';
const BNET_REMOVE_CODE = '/bnet/remove';


const CHAR = '/characters';

const BNET_RETRIEVE_CHARACTERS = CHAR + '/retrieve';
const WC_CHAR_ME =  CHAR + '/me';
const WC_CHAR_MAIN =  CHAR + '/main';
const WC_CHAR_DELETE = CHAR + '/delete';
const WC_CHAR_SHOW =  CHAR + '/show';
const WC_CHAR_UPDATE =  CHAR + '/update';
const WC_CHAR_SPECIFIC = CHAR + '/';

const STATIC_EXPANSIONS = '/static/expansions';
const STATIC_INSTANCES = '/static/instances'

const LIST = '/list';
const LIST_NEW = LIST + '/new';
const LIST_CLOSE = LIST + '/close';
const LIST_DELETE = LIST + '/delete';
const SIGNUPS = LIST + '/signups';
const SIGNUPS_REMOVE = SIGNUPS + '/remove';
const SIGNUPS_CREATE = SIGNUPS + '/new';
const SIGNUPS_COMMENT = SIGNUPS + '/comment';
const FRONTPAGE = LIST + '/frontpage';

export {
    USER,
    USER_ME,
    USER_ROLE,
    USER_LISTS,
    USER_SIGNUPS,
    USER_NOTIFICATIONS,
    USER_NOTIFICATION_SINGLE,
    USER_MESSAGES,
    _LOGIN,
    _REGISTER,
    _FORGOTPASSWORD,
    _RESETPASSWORD,
    BNET_UPDATE_CODE,
    BNET_REMOVE_CODE,
    BNET_RETRIEVE_CHARACTERS,
    BNET_GET,
    WC_CHAR_ME,
    WC_CHAR_SPECIFIC,
    STATIC_EXPANSIONS,
    STATIC_INSTANCES,
    LIST_NEW,
    LIST,
    LIST_CLOSE,
    LIST_DELETE,
    FRONTPAGE,
    SIGNUPS,
    SIGNUPS_REMOVE,
    SIGNUPS_CREATE,
    SIGNUPS_COMMENT,
    WC_CHAR_MAIN,
    WC_CHAR_DELETE,
    WC_CHAR_SHOW,
    WC_CHAR_UPDATE
}
