<template>
  <div class="wcg-center">
    <div style="width: 400px">
      <div class="wcg-content wcg-full-height">
        <div class="wcg-header">
          <a href="/">
            <img src="/WG_logo_big.svg" />
            &nbsp;<span style="border-bottom: 1px solid grey; font-weight: bold"
              ><span>Warcraft</span><span class="sub">Groups</span></span
            >
          </a>
        </div>
        <div>
          <div class="has-text-white" style="position: relative">
            <h1 class="title has-text-white pt-4 mb-2 has-text-centered">
              Password reset
            </h1>
            <div v-if="!loaded">
              <loader></loader>
            </div>
            <div v-show="loaded">
              <form class="p-3" v-show="!$route.query.c" @submit.prevent="forgot">
                <div class="field">
                  <label class="label has-text-white">E-Mail:</label>
                  <div class="control">
                    <input
                      class="input"
                      type="email"
                      id="email"
                      autocomplete="email"
                      v-model="email"
                      required
                    />
                    <p class="error" v-if="error">
                      {{ error }}
                    </p>
                  </div>
                </div>
                <div class="field">
                  <button
                    class="button is-dark is-fullwidth"
                    type="submit"
                    @click="forgot"
                  >
                    <span>Send me an Email</span>
                  </button>
                </div>
                <div class="field has-text-white pt-2">
                  Back to
                  <router-link to="/login">Login</router-link>
                </div>
              </form>
              <form class="p-3" v-show="$route.query.c" @submit="reset">
                <div class="field">
                  <label class="label has-text-white">New Password:</label>
                  <div class="control">
                    <input
                      class="input"
                      type="password"
                      id="password"
                      v-model="password"
                      required
                    />
                    <p class="error" v-if="error">
                      {{ error }}
                    </p>
                  </div>
                </div>
                <div class="field">
                  <button
                    class="button is-dark is-fullwidth"
                    type="submit"
                  >
                    <span>Reset my password</span>
                  </button>
                </div>
                <div class="field has-text-white pt-2">
                  Back to
                  <router-link to="/login">Login</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="faded-footer">
      <div class="has-text-white" style="opacity: 0.7">
        <p>Images by Blizzard Entertainment™</p>
        <p>
          World of Warcraft, Warcraft and Blizzard Entertainment are trademarks
          or registered trademarks of Blizzard Entertainment, Inc. in the U.S.
          and/or other countries.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../store/index";
import { PASSWORD_RESET } from "../../store/actions/auth";
import { mapGetters } from "vuex";

import Loader from "../../components/Loader.vue";
import { METHOD_POST } from "../../utility/globals";
import { _FORGOTPASSWORD, _RESETPASSWORD } from "../../utility/routes";
import { lData } from "../../utility/db";

export default {
  components: { Loader },
  data() {
    return {
      email: "",
      password: "",
      loaded: true,
      error: "",
    };
  },
  computed: {
    ...mapGetters(["getLoginState"]),
  },
  methods: {
    forgot: function () {
      this.loaded = false;
      this.error = "";
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, _FORGOTPASSWORD, {
          email: this.email,
        })
          .then((res) => {
            if (res.data.status == "success") {
              this.$router.push(`/login`);
            }

            this.loaded = true;
            resolve();
          })
          .catch((err) => {
            if (typeof err.response.data.email !== "undefined") {
              this.error = err.response.data.email[0];
            }else {
                this.error = err.response.data;
            }
            
            this.loaded = true;
            reject();
          });
      });
    },
    reset: function () {
      this.loaded = false;
      this.error = "";
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, _RESETPASSWORD, {
          c: this.$route.query.c,
          password: this.password,
        })
          .then((res) => {
            if (res.data.status == "success") {
              this.$router.push(`/login`);
            }

            this.loaded = true;
            resolve();
          })
          .catch((err) => {
            if (typeof err.response.data.password !== "undefined") {
              this.error = err.response.data.password[0];
            }else{
                this.error = err.response.data;
            }

            this.loaded = true;
            reject();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: #ffb6b6;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  text-shadow: 1px 1px black;
}
</style>

