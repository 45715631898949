import { createStore } from 'vuex'
import { createApp } from 'vue'
import createPersistedState from 'vuex-persistedstate';
import auth from "./modules/moduleAuth";
import user from "./modules/moduleUser";

export default createStore({
  plugins: [createPersistedState()],
  modules:{
    auth,
    user
  }
});