
import axios from "axios";
import { lData } from "../../utility/db.js";
import { USER_LOGOUT, USER_REQUEST } from "../actions/user";
import { AUTH_REQUEST, AUTH_LOGOUT, AUTH_FAIL, AUTH_SUCCESS, AUTH_RESET, AUTH_REGISTER } from "../actions/auth";
import { METHOD_POST, JWT } from "../../utility/globals";

import { _LOGIN, _REGISTER } from "../../utility/routes";

const storeAuth = {
    state: {
        token: localStorage.getItem(JWT) || '',
        loginState: ''
    },
    getters: {
        isAuthenticated: state => !!state.token,
        getLoginState: state => state.loginState
    },
    mutations: {
        [AUTH_REQUEST]: (state) => {
            state.loginState = 'pending';
        },
        [AUTH_FAIL]: (state, res) => {
            state.loginState = res;
        },
        [AUTH_SUCCESS]: (state, mToken) => {
            state.loginState = '';
            state.token = mToken;
        },
        [AUTH_LOGOUT]: (state) => {
            return new Promise((resolve, reject) => {
                state.loginState = '';
                state.token = '';
                localStorage.removeItem(JWT);
                resolve();
                //Catch?
            })
        },
        [AUTH_RESET]: (state) => {
            state.loginState = '';
        },
    },
    actions: {
        [AUTH_RESET]: ({ commit, dispatch }) => {
            commit(AUTH_RESET);
        },
        [AUTH_REQUEST]: ({ commit, dispatch }, uObj) => {
            commit(AUTH_RESET);
            return new Promise((resolve, reject) => {
                commit(AUTH_REQUEST);
                lData(METHOD_POST, _LOGIN, {
                    email: uObj.email,
                    password: uObj.password
                }).then(function (res) {
                    if(res.headers.authorization != null){
                        commit(AUTH_SUCCESS, res.headers.authorization);
                        localStorage.setItem(JWT, res.headers.authorization);
                        axios.defaults.headers.common['Authorization'] = res.headers.authorization;
                        resolve(res.data);
                    }else{
                        commit(AUTH_FAIL, res);
                        reject(res);
                    }
                }).catch(function (err) {
                    if (typeof err.response === 'undefined' ) {
                        return;
                    }
                    let loginStateLocal = "";
                    
                    switch (err.response.status) {
                        case 422:
                            loginStateLocal = err.response.data;
                            break;
                        case 401:
                            loginStateLocal = {email: ["Invalid Credentials"], password: ["Invalid Credentials"]};
                            break;
                        
                        default:
                            break;
                    }
                    commit(AUTH_FAIL,loginStateLocal );
                    reject(err);
                })
            })
        },
        [AUTH_REGISTER]: ({ commit, dispatch }, uObj) => {
            commit(AUTH_RESET);
            return new Promise((resolve, reject) => {
                commit(AUTH_REQUEST);
                lData(METHOD_POST, _REGISTER, {
                    username: uObj.username,
                    email: uObj.email,
                    password: uObj.password
                }).then(function (res) {
                    if(res.headers.authorization != null){
                        resolve(res.data);
                    }else{
                        commit(AUTH_FAIL, res);
                        reject(res);
                    }
                }).catch(function (err) {
                    console.log(err);
                    if (typeof err.response === 'undefined' ) {
                        return;
                    }
                    let loginStateLocal = err.response.data;
                    commit(AUTH_FAIL,loginStateLocal );
                    reject(err);
                })
            })
        },
        [AUTH_LOGOUT]: ({ commit }) => {
            commit(AUTH_LOGOUT);
            commit(USER_LOGOUT);
        }
    }
}

export default storeAuth
