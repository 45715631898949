import { lData } from "../../utility/db";

import { METHOD_GET, METHOD_POST } from "../../utility/globals";
import { USER_CHARS, USER_LOGOUT, USER_REQUEST, USER_NOTIFICATION } from "../actions/user";
import { USER_ME, USER_NOTIFICATIONS } from "../../utility/routes";
import { AUTH_LOGOUT } from "../actions/auth";
import moment from "moment";


const storeUser = {
  state: {
    userObject: {},
    charArray: [],
    bnetObject: {},
    notificationArray: [],
    messageArray: []
  },
  getters: {
    getUserData: state => state.userObject,
    getBnetObject: state => state.bnetObject,
    getLoginId: state => (state.userObject.id !== undefined) ? state.userObject.id : 0,
    getCharArray: state => state.charArray,
    getNotificationArray: state => state.notificationArray,
    getMessageArray: state => state.messageArray,
    getOutdatedArray: state => state.charArray.filter(char => (moment( char.last_sync) < moment().subtract(1, 'days') || !char.level) && char.show).length
  },
  mutations: {
    [USER_REQUEST]: (state, res) => {
      state.userObject = res.data;
      state.bnetObject = res.acc;
      state.charArray = res.chars;
      state.notificationArray = res.notifications;
      state.messageArray = res.messages;
    },
    [USER_LOGOUT]: (state) => {
      state.userObject = {};
      state.charArray = [];
      state.bnetObject = {};
      state.notificationArray = [];
      state.messageArray = [];
    },
    [USER_CHARS]: (state, res) => {
      state.charArray = res;
    },
    [USER_NOTIFICATION]: (state, res) => {
      state.notificationArray = res;
    },
  },
  actions: {
    [USER_REQUEST]: ({ commit, dispatch }) => {
      
      var uObj = {};
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, USER_ME).then(function (res) {
          uObj = {...res.data};
          commit(USER_LOGOUT);
          commit(USER_REQUEST, uObj);
          resolve(uObj);
        }).catch(function (err) {
          dispatch(AUTH_LOGOUT);
          reject(err);
        });
      });
    },
    [USER_NOTIFICATION]: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        lData(METHOD_GET, USER_NOTIFICATIONS).then(function (res) {
          commit(USER_NOTIFICATION, res.data.data);
          resolve(res.data);
        }).catch(function (err) {
          reject(err);
        });
      });
    },
  }
}

export default storeUser
