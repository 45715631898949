<template>
  <nav id="v-nav">
    <div class="v-nav-image">
      <div class="v-nav-image_background"></div>
      <div class="v-nav-image_file"></div>
    </div>
    <div class="v-nav-content">
      <div class="px-2">
        <a href="/" class="" tabindex="0">
          <img
            src="/WG_logo_big.svg"
            alt=""
            class="p-10 is-centered"
            style="max-height: 150px"
          />
          <h2 id="nav-brand">
            <span><span>Warcraft</span><span class="sub">Groups</span></span>
          </h2>
        </a>
        <hr role="separator" aria-orientation="horizontal" class="m-2" />
        <div class="nav-list-group" v-if="isAuthenticated">
          <!-- <div class="nav-list-user_image">
            <img
              src="https://lh3.googleusercontent.com/a-/AOh14GiGKd-xhJXTj8E0ROviqz_SfjnOT6or6rKXv7hi5w=s96-c"
              alt=""
            />
          </div> -->
          <h1 id="nav-username">
            {{ getUserData.name }}
          </h1>
          <hr role="separator" aria-orientation="horizontal" class="m-2" />
          <router-link
            class="nav-list-item has-text-white"
            :to="{ path: '/user/' + getUserData.id }"
            exact
            v-if="isAuthenticated"
            ><div class="nav-list-item_image">
              <i class="far fa-id-card"></i>
            </div>
            <div class="nav-list-item_content">
              Mein Profil
            </div></router-link
          >
          <router-link
            class="nav-list-item has-text-white"
            to="/settings"
            exact
            v-if="isAuthenticated"
            ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-user-cog"></i>
            </div>
            <div class="nav-list-item_content">
              Settings
            </div></router-link
          >
          <!-- <a class="nav-list-item has-text-white" v-if="isAuthenticated"
            ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-inbox" aria-hidden="true"></i>
            </div>
            <div class="nav-list-item_content">
              Nachrichten
            </div></a
          > -->
        </div>

        <div class="nav-list-group" v-if="!isAuthenticated">
          <router-link class="nav-list-item has-text-white" to="/Login" exact
            ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-sign-in-alt"></i>
            </div>
            <div class="nav-list-item_content">
              Login
            </div></router-link
          >
          <router-link class="nav-list-item has-text-white" to="/Register" exact
          ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-user-plus"></i>
            </div>
            <div class="nav-list-item_content">
              Register
            </div></router-link
          >
        </div>
        <hr role="separator" aria-orientation="horizontal" class="m-2" />
        <div class="nav-list-group">
          <router-link
            class="nav-list-item has-text-white"
            to="/list/search"
            exact
            ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-user-cog"></i>
            </div>
            <div class="nav-list-item_content">Suche</div></router-link
          >

          <router-link
            class="nav-list-item has-text-white"
            to="/new"
            exact
            v-if="isAuthenticated"
            ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-plus-square"></i>
            </div>
            <div class="nav-list-item_content">
              Neue Gruppe
            </div></router-link
          >
        </div>
        <hr role="separator" aria-orientation="horizontal" class="m-2" />
        <div class="nav-list-group">
          <a
            class="nav-list-item has-text-white is-discord"
            href="https://Discord.gg/liebe"
            ><div class="nav-list-item_image">
              <i class="fab fa-lg fa-discord"></i>
            </div>
            <div class="nav-list-item_content">
              Team Liebe
            </div></a
          >
          <router-link
            class="nav-list-item has-text-white"
            to="/changelog"
            exact
            v-if="isAuthenticated"
            ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-file-alt"></i>
            </div>
            <div class="nav-list-item_content">
              Changelog
            </div></router-link
          >
          <a
            class="nav-list-item has-text-white"
            @click="logout"
            v-if="isAuthenticated"
            ><div class="nav-list-item_image">
              <i class="fas fa-lg fa-sign-out-alt"></i>
            </div>
            <div class="nav-list-item_content">
              Logout
            </div></a
          >
        </div>
      </div>

      <!-- 
<div
    class="notification-box"
    v-bind:class="{ hidden: !isActiveNotificationMode }"
    @click="toggleNotificationView(true)"
  >
    <p class="empty-box" v-if="getNotificationArray.length == 0">
      Nichts neues hier!
    </p>
    <div v-else class="notification-inner-box">
      <div
        class="notification is-dark"
        v-for="note in getNotificationArray"
        v-bind:key="note"
      >
        <button class="delete" @click="markAsRead(note.id)"></button>
        <h3>
          {{ note.title }}
          <small class="is-small has-text-grey-light">{{
            formatDate(note.created_at)
          }}</small>
        </h3>
        <p v-html="note.content"></p>
      </div>
    </div>
    <button
      v-if="getNotificationArray.length > 0"
      class="button is-highlight is-outlined is-fullwidth"
      @click="markAllAsRead()"
    >
      <i class="fas fa-check"></i>&nbsp;Mark all as read
    </button>
  </div>
  <div
    class="notification-box"
    v-bind:class="{ hidden: isActiveNotificationMode }"
    @click="toggleNotificationView(true)"
  >
    <p class="empty-box">Coming soon!</p>
  </div> -->
    </div>
  </nav>
</template>

<script>
import moment from "moment";

import store from "../store/index";
import { AUTH_REQUEST, AUTH_LOGOUT } from "../store/actions/auth";
import { mapGetters } from "vuex";
import { lData } from "../utility/db";
import { METHOD_POST } from "../utility/globals";
import {
  USER_NOTIFICATIONS,
  USER_NOTIFICATION_SINGLE,
} from "../utility/routes";
import { USER_NOTIFICATION } from "../store/actions/user";

export default {
  name: "Header",
  data() {
    return {
      isActiveClass: false,
      isActiveNotificationMode: true,
      timer: "",
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getUserData",
      "getNotificationArray",
      "getMessageArray",
    ])
  },
  created() {
    this.timer = setInterval(() => {
      this.$store.dispatch(USER_NOTIFICATION);
    }, 60000);
  },
  methods: {
    formatDate: function(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    toggleNotificationView: function(value) {
      this.isActiveNotificationMode = value;
    },
    toggleIsActive: function() {
      this.isActiveClass = !this.isActiveClass;
    },
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/");
      });
      //Catch?
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    markAsRead(id) {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, USER_NOTIFICATION_SINGLE, { notify_id: id })
          .then(function(res) {
            resolve();
          })
          .catch(function(err) {
            reject(err);
          });
      }).then(() => {
        this.$store.dispatch(USER_NOTIFICATION);
      });
    },
    markAllAsRead() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, USER_NOTIFICATIONS)
          .then(function(res) {
            resolve();
          })
          .catch(function(err) {
            reject(err);
          });
      }).then(() => {
        this.$store.dispatch(USER_NOTIFICATION);
      });
    },
  },
};
</script>

<style lang="css" scoped>
.navbar-dropdown {
  background-color: #333;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
}
.navbar-dropdown > a {
  color: white;
}
.navbar-dropdown > hr {
  margin: 0;
}
</style>
