

<template>
  <div>
    <h1 class="title is-1 has-text-white adsimple-321233880">Impressum</h1>
    <p class="adsimple-321233880">Informationspflicht laut § 5 TMG.</p>
    <p class="adsimple-321233880">AFK Creators Ltd</p>
    <p class="adsimple-321233880">
      Third Floor, 24 Chiswell Street, <br />London, <br />EC1Y 4YX
    </p>
    <p class="adsimple-321233880">
      <strong class="has-text-white">Tel.:</strong> Auf Anfrage<br />
      <strong class="has-text-white">E-Mail:</strong>
      <a class="has-text-highlight" href="mailto:contact@warcraftgroups.com"
        > contact@warcraftgroups.com</a
      >
    </p>
    <h2 class="title is-2 has-text-white adsimple-321233880">
      EU-Streitschlichtung
    </h2>
    <p>
      Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten
      (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform
      (OS-Plattform) informieren.<br />
      Verbraucher haben die Möglichkeit, Beschwerden an die Online
      Streitbeilegungsplattform der Europäischen Kommission unter
      <a
        class="has-text-highlight adsimple-321233880"
        href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
        target="_blank"
        rel="noopener"
        >http://ec.europa.eu/odr?tid=321233880</a
      >
      zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
      unserem Impressum.
    </p>
    <p>
      Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
      verpflichtet sind, an Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h2 class="title is-2 has-text-white adsimple-321233880">
      Haftung für Inhalte dieser Website
    </h2>
    <p>
      Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns
      korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz
      <a
        class="has-text-highlight adsimple-321233880"
        href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321233880"
        rel="noopener"
        target="_blank"
        >(TMG) §7 (1)</a
      >
      sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung
      bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können
      wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite
      übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
      Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet,
      die von ihnen übermittelten oder gespeicherten Informationen zu überwachen
      oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen.
    </p>
    <p>
      Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung
      der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von
      gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer
      Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
    </p>
    <p>
      Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte
      wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte
      entfernen können. Sie finden die Kontaktdaten im Impressum.
    </p>
    <h2 class="title is-2 has-text-white adsimple-321233880">
      Haftung für Links auf dieser Website
    </h2>
    <p>
      Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir
      nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns
      nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben,
      uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir
      Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
    </p>
    <p>
      Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir
      Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
    </p>
    <h2 class="title is-2 has-text-white adsimple-321233880">
      Urheberrechtshinweis
    </h2>
    <p>
      Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen
      dem Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns
      bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder
      verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen.
      Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte
      unserer Seite rechtlich verfolgen.
    </p>
    <p>
      Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
      verletzen, bitten wir Sie uns zu kontaktieren.
    </p>
    <h2 class="title is-2 has-text-white adsimple-321233880">Bildernachweis</h2>
    <p>
      Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
      geschützt.
    </p>
    <p>Die Bilderrechte für <i>World of Wacraft</i>-bezogenes Material liegen bei <i>Blizzard Entertainment</i>.</p>
  </div>
</template>



<script>
export default {
  name: "PrivacyPolicy",
};
</script>