<template>
  <div v-if="!loaded">
    <loader></loader>
  </div>
  <div v-else>
    <meta-patcher :title="(getBnetObject) ? getBnetObject.battletag : ''" :description="'All your settings!'" />
    <div ref="mainContainer" class="container">
      <div class="columns">
        <div class="column is-12-mobile">
          <div class="row mbg">
            <h1 class="title is-1 has-text-white" style="position: relative">
              <span>Blizzard Settings</span>
            </h1>
            <h1 class="title is-4 has-text-white bordered-h1">
              <small> Info: </small>
            </h1>
            <p>
              <span
                >Indem du deinen Blizzard Account mit WarcraftGroups verbindest,
                ermöglichest du es uns deine Charaktere auszulesen. Somit können
                wir sicherstellen, dass diese Charaktere dir gehören. Mit diesen
                kannst du dich dann bei diversen Listen anmelden.</span
              >
              <span class="is-italic">
                Es werden nur Charaktere auf dem maximalen Level
                importiert.</span
              >
            </p>
            <p class="has-text-highlight">
              Solltest du Probleme haben mit der Authentifizierung mit Battle.Net haben, lösche unsere seite aus <a href="https://account.blizzard.com/connections" class="has-text-danger">Blizzard Connections</a> heraus und versuche es erneut.
            </p>
            <h4 class="title is-4 has-text-white bordered-h1">
              Verknüpfter Account:
            </h4>
            <div v-if="getBnetObject">
              <div class="field is-grouped is-grouped-multiline">
                <div class="control">
                  <div class="tags has-addons">
                    <span class="tag is-medium is-dark"
                      ><i class="fas fa-globe-europe"></i
                    ></span>
                    <span class="tag is-medium">{{
                      getBnetObject.region
                    }}</span>
                  </div>
                </div>

                <div class="control">
                  <div class="tags has-addons">
                    <span class="tag is-medium is-dark"
                      ><i class="fab fa-battle-net"></i
                    ></span>
                    <span class="tag is-medium">{{
                      getBnetObject.battletag
                    }}</span>
                  </div>
                </div>

                <div class="control">
                  <div class="tags has-addons">
                    <span class="tag is-medium is-dark"
                      ><i class="fas fa-clock"></i
                    ></span>
                    <span class="tag is-medium">{{
                      formatDate(getBnetObject.last_sync)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <p class="has-text-highlight" v-if="!getBnetObject">
              Du hast bisher keinen Battle.Net Account verknüpft.
            </p>
            <hr />
            <div class="columns">
              <div class="column">
                <button
                  class="button bnet-button has-text-white is-fullwidth"
                  @click="oauthBattleNet"
                >
                  <i class="fab fa-battle-net"></i>&nbsp;<span
                    v-if="!getBnetObject"
                    >Verknüpfe einen Account</span
                  ><span v-else>Synchronisieren</span>
                </button>
              </div>
              <div class="column">
                <button
                  v-if="getBnetObject"
                  class="button is-highlight has-text-white is-fullwidth"
                  @click="removeBlizzardAccount"
                >
                  <i class="fab fa-battle-net"></i>&nbsp;<span
                    >Verknüpfung aufheben</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12-mobile">
          <div class="row mbg">
            <h1 class="title is-1 has-text-white" style="position: relative">
              <span>Verknüpfte Charaktere</span>
              <div class="is-pulled-right">
                <button class="button is-danger" type="button">
                  <i class="fas fa-sync"></i>&nbsp;Alle Charaktere updaten
                </button>
              </div>
            </h1>
            <p v-if="!getBnetObject">
              Verknüpfe deinen Account um deine Charaktere hier zu finden,
              aktualisieren und als Main/Twink zu markieren.
            </p>
            <p v-if="!characters">
              Keine Charaktere auf maximalem Level gefunden.
            </p>
            <div v-if="characters">
              <div
                class="characterRow"
                v-for="solo in characters"
                v-bind:key="solo"
              >
                <img
                  onerror="this.onerror=null;this.src='/img/character_placeholder.png';"
                  v-bind:src="
                    solo.avatar_image || '/img/character_placeholder.png'
                  "
                  alt=""
                />
                <router-link
                  :to="{
                    path: '/character/' + solo.realm + '/' + solo.character,
                  }"
                  exact
                  class="hoverObjectJumbotronHref"
                >
                  <div class="subJumbotron mbg">
                    <div class="media-heading-long">
                      <div class="imageinfo">
                        <span class="img">
                          <img
                            v-bind:src="
                              '/img/spec/' + getFormedSpan(solo, true) + '.png'
                            "
                            onerror="this.onerror=null;this.src='/favicon.png';"
                            height="20"
                            width="20"
                          />
                        </span>
                        <span class="img">
                          <img
                            v-bind:src="
                              '/img/class/' + getFormedSpan(solo) + '.png'
                            "
                            onerror="this.onerror=null;this.src='/favicon.png';"
                            height="20"
                            width="20"
                          />
                        </span>
                      </div>

                      <div class="info">
                        <span
                          v-bind:class="
                            solo.myclass ? 'class-' + getFormedSpan(solo) : ''
                          "
                          >{{ solo.character }}-{{ solo.realm }}
                          <i>(Lvl. {{ solo.level }})</i></span
                        ><i
                          class="far fa-calendar-alt"
                          v-bind:title="formatDate(solo.last_sync)"
                        ></i>
                      </div>
                    </div>
                  </div>
                </router-link>
                <div style="text-align: center; padding: 20px">
                  <div class="containerOuter">
                    <input
                      v-model="mainchar"
                      v-bind:checked="solo.isMain"
                      v-bind:value="solo.id"
                      v-bind:name="'radiomain'"
                      type="radio"
                      class="hidden highlight-button"
                      v-bind:id="getFormedId(solo.id, 'radio')"
                      @change="changeMainChar"
                    />
                    <label
                      class="button is-small is-dark"
                      v-bind:for="getFormedId(solo.id, 'radio')"
                      ><div class="circle"></div>
                      <div class="entry-label">Main</div></label
                    >
                  </div>
                </div>
                <div style="text-align: center; padding: 20px">
                  <div class="is-pulled-right">
                    <button
                      class="button is-danger is-small"
                      type="button"
                      disabled="disabled"
                      v-if="Object.keys(solo.updates).length > 0"
                    >
                      <i class="fas fa-sync fa-spin"></i>
                    </button>
                    <button
                      class="button is-highlight is-small"
                      type="button"
                      v-else
                      @click="addUpdateQue(solo)"
                    >
                      <i class="fas fa-sync"></i>
                    </button>
                  </div>
                </div>
                <div style="text-align: center; padding: 20px">
                  <span class="is-pulled-right">
                    <div class="field">
                      <input
                        v-bind:id="'switch' + solo.id"
                        type="checkbox"
                        v-bind:name="'switch' + solo.id"
                        class="switch is-outlined"
                        v-bind:checked="solo.show"
                        @change="changeCharVisibility(solo)"
                      />
                      <label v-bind:for="'switch' + solo.id">Öffentlich</label>
                    </div>
                  </span>
                </div>
                <div style="text-align: center; padding: 20px">
                  <div class="is-pulled-right">
                    <button
                      class="button is-danger is-small"
                      type="button"
                      @click="removeCharacter(solo)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import store from "../../store/index";
import { AUTH_REQUEST, AUTH_LOGOUT } from "../../store/actions/auth";
import { USER_REQUEST } from "../../store/actions/user";
import Swal from 'sweetalert2';
import {
  BNET_UPDATE_CODE,
  WC_CHAR_ALL,
  BNET_GET,
  BNET_REMOVE_CODE,
  WC_CHAR_ME,
  WC_CHAR_MAIN,
  WC_CHAR_DELETE,
  WC_CHAR_SHOW,
  WC_CHAR_UPDATE,
} from "../../utility/routes";
import { mapGetters } from "vuex";
import { lData } from "../../utility/db";
import { METHOD_POST, METHOD_GET, METHOD_DELETE } from "../../utility/globals";

import BulmaSwitch from "bulma-switch";
import Loader from "../../components/Loader.vue";
import MetaPatcher from "../../components/MetaPatcher.vue";

export default {
  components: { Loader, MetaPatcher },
  name: "Settings",
  data() {
    return {
      loaded: false,
      characters: null,
      mainchar: null,
    };
  },
  metaInfo () {
    return {
      title: "this.page"
    }
  },
  created: function () {
    if (this.$route.query.code) {
      lData(METHOD_POST, BNET_UPDATE_CODE, { code: this.$route.query.code })
        .then(() => {
          // this.$router.push("/settings");

          this.$store
            .dispatch(USER_REQUEST)
            .then(this.loadCharacters)
            .then(() => {
              this.loaded = true;
            });
        })
        .catch(() => {});
    } else if (this.bnet == null) {
      this.$store.dispatch(USER_REQUEST).then(() => {
        this.characters = this.getCharArray;
        this.loaded = true;
      });
    }
  },
  mounted: function () {},
  computed: {
    ...mapGetters(["getBnetObject", "getCharArray"]),
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    oauthBattleNet() {
      let Region = "eu";
      let href = `https://${Region}.battle.net/oauth/authorize?response_type=code&client_id=${
        process.env.VUE_APP_BLIZZARD_CLIENT_ID
      }&redirect_uri=${encodeURIComponent(
        process.env.VUE_APP_BLIZZARD_REDIRECT_URI
      )}&scope=wow.profile&state=es`;
      window.location.href = href;
    },
    // loadBlizzardAccount() {
    //   return new Promise((resolve, reject) => {
    //     lData(METHOD_GET, BNET_GET)
    //       .then((res) => {
    //         if (res.data.status == "success") {
    //           this.bnet = res.data.response;
    //         }
    //         resolve();
    //       })
    //       .catch(() => {
    //         reject();
    //       });
    //   });
    // },
    loadCharacters() {
      return new Promise((resolve, reject) => {
        lData(METHOD_GET, WC_CHAR_ME)
          .then((res) => {
            if (res.data.status == "success") {
              this.characters = res.data.response;
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    getFormedId(id, type) {
      return "input_" + type + "_" + id;
    },
    getFormedSpan(obj, spec) {
      if (obj != null) {
        if (typeof spec !== "undefined" && obj.myspecification !== null) {
          return (
            obj.myspecification.name.toLowerCase().replace(" ", "-") +
            "_" +
            obj.myclass.name.toLowerCase().replace(" ", "-")
          );
        } else {
          return obj.myclass.name.toLowerCase().replace(" ", "-");
        }
      }
    },
    removeBlizzardAccount() {
      return new Promise((resolve, reject) => {
        lData(METHOD_DELETE, BNET_REMOVE_CODE)
          .then((res) => {
            if (res.data.status == "success") {
              this.$store.dispatch(USER_REQUEST);
              resolve();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    removeCharacter(character){
      Swal.fire({
				title: "Wirklich löschen?",
				icon: "error",
				// toast: true,
				// position: "top-end",
				showConfirmButton: true,
        showCancelButton: true,
				// timer: 1000,
			}).then(r => {
        if (r.value) {
          new Promise((resolve, reject) => {
            lData(METHOD_POST, WC_CHAR_DELETE, {
              character_id: character.id,
            })
              .then((res) => {
                if (res.data.status == "success") {
                  this.loadCharacters();
                  resolve();
                }
              })
              .catch((res) => {
                reject();
              });
          }); 
        }
      });
    },
    changeMainChar() {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, WC_CHAR_MAIN, {
          character_id: this.mainchar,
        })
          .then((res) => {
            if (res.data.status == "success") {
              resolve();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    addUpdateQue(character) {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, WC_CHAR_UPDATE, {
          character_id: character.id,
        })
          .then((res) => {
            if (res.data.status == "success") {
              this.loadCharacters();
              resolve();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    changeCharVisibility(character) {
      return new Promise((resolve, reject) => {
        lData(METHOD_POST, WC_CHAR_SHOW, {
          character_id: character.id,
          show: !character.show,
        })
          .then((res) => {
            if (res.data.status == "success") {
              resolve();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bnet-button {
  background: #1a84ea;
  border-color: #1a84ea;
}
.bnet-button:hover {
  background: #0876dd;
}
.bordered-h1 {
  border-bottom: 1px solid #333;
}

div.imageinfo {
  position: absolute;
  span.img {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.625rem;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    border-radius: 0.25rem;
    line-height: 1;
    color: #fff;
    margin-right: 0.5rem;
    position: relative;
  }
}

.media-heading-long .info {
  margin-left: 60px;
}
</style>