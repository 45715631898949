<template>
  <div
    v-bind:class="classGenerator"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <img v-bind:src="itemObject.item.icon.path" alt="" v-if="itemObject.item" />
    <div v-if="itemObject.item">
      <span
        v-bind:class="colorBasedOnRarity(itemObject.item.info_object.quality)"
        >{{ itemObject.item.name }}</span
      >
      <span class="has-text-weight-normal"
        ><span><b>{{ itemObject.item.level }}</b></span
        ><span
          v-if="itemObject.item.info_object.enchantments"
          class="is-active-effect"
          >{{
            enchantExp(itemObject.item.info_object.enchantments[0].display_string)
          }}</span
        >
        <span
          v-for="socket in itemObject.item.info_object.sockets"
          v-bind:key="socket"
          class="socketHandler"
        >
          <div class="socketItself"></div>
          <div class="socketBackground"></div>
        </span>
      </span>
    </div>

    <div
      v-if="itemObject.item"
      class="TOOLTIPFollower"
      v-bind:class="{ 'is-active': hover }"
    >
      <div class="item-object" v-if="itemObject.item.info_object">
        <div class="definition">
          <h3
            class="item-name"
            v-bind:class="
              colorBasedOnRarity(itemObject.item.info_object.quality)
            "
          >
            {{ itemObject.item.name }}
          </h3>
          <div
            class="item-description-type"
            v-if="itemObject.item.info_object.name_description"
            v-bind:style="
              colorGenerator(itemObject.item.info_object.name_description.color)
            "
          >
            {{ itemObject.item.info_object.name_description.display_string }}
          </div>
          <div class="item-level has-text-warning">
            Item Level {{ itemObject.item.level }}
          </div>
          <div
            class="item-unique"
            v-if="itemObject.item.info_object.limit_category"
          >
            {{ itemObject.item.info_object.limit_category }}
          </div>
          <div class="item-spec">
            <div class="item-spec-place">
              {{ itemObject.item.info_object.slot
              }}<i>{{ itemObject.item.info_object.item_subclass }}</i>
            </div>
          </div>
          <div class="item-attributes" v-if="itemObject.item.info_object.stats">
            <div
              class="item-attribute"
              v-for="stat in itemObject.item.info_object.stats"
              v-bind:key="stat"
              v-bind:style="colorGenerator(stat.display.color)"
            >
              {{ stat.display.display_string }}
            </div>
          </div>
          <div
            class="item-enchantments"
            v-if="itemObject.item.info_object.enchantments"
          >
            <div
              class="is-active-effect"
              v-for="enchant in itemObject.item.info_object.enchantments"
              v-bind:key="enchant"
            >
              {{ enchantExp(enchant.display_string) }}
            </div>
          </div>
          <div class="item-sockets" v-if="itemObject.item.info_object.sockets">
            <div
              v-for="socket in itemObject.item.info_object.sockets"
              v-bind:key="socket"
              style="display: table; padding-right: 2px; padding-left: 12px"
            >
              <div style="display: table-cell">
                <div class="socketHandler">
                  <div class="socketItself"></div>
                  <div class="socketBackground"></div>
                </div>
              </div>
              <div
                style="
                  display: table-cell;
                  padding-left: 10px;
                  line-height: 23px;
                "
              >
                {{ socket.display_string }}
              </div>
            </div>
          </div>
          <div class="item-spells" v-if="itemObject.item.info_object.spells">
            <p
              class="item-spell"
              style="color: rgb(0, 255, 0)"
              v-for="spell in itemObject.item.info_object.spells"
              v-bind:key="spell"
            >
              {{ spell.description }}
            </p>
          </div>
          <div class="item-set" v-if="itemObject.item.info_object.set">
            <span
              v-bind:class="{
                'has-text-warning': itemObject.item.info_object.set,
              }"
              >{{ itemObject.item.info_object.set.display_string }}</span
            >
            <ul>
              <li
                v-for="setItem in itemObject.item.info_object.set.items"
                v-bind:key="setItem"
                v-bind:class="{ 'is-disabled-effect': !setItem.is_equipped }"
              >
                {{ setItem.item.name }}
              </li>
            </ul>
            <p
              class="item-spell"
              v-bind:class="{
                'is-active-effect': effect.is_active,
                'is-disabled-effect': !effect.is_active,
              }"
              v-for="effect in itemObject.item.info_object.set.effects"
              v-bind:key="effect"
            >
              {{ effect.display_string }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "EQItem",
  data() {
    return {
      hover: false,
    };
  },
  props: ["itemObject"],
  computed: {
    classGenerator() {
      return "slot" + this.itemObject.slot;
    },
  },
  methods: {
    enchantExp($g){
      let r = new RegExp(/(.*)(\|A)(.*)(\|a)/g);
      let result = r.exec($g);
      if (result != null) {
        let r = result[1];
        return r.trim(); 
      }else{
        return $g;
      }
    },
    colorGenerator($obj) {
      return (
        "color:rgba(" +
        $obj.r +
        "," +
        $obj.g +
        "," +
        $obj.b +
        "," +
        $obj.a +
        ")"
      );
    },
    colorBasedOnRarity($obj) {
      return "color-" + $obj;
    },
  },
};
</script>